import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SelfcareRoutingModule } from './selfcare-routing.module';
import { SelfcareLayoutComponent } from './components/selfcare-layout/selfcare-layout.component';
import { TopupComponent } from './pages/topup/topup.component';
import { SelfcareNavbarComponent } from './components/selfcare-navbar/selfcare-navbar.component';
import { SharedModule } from 'src/app/@shared';
import { NgSelect2Module } from 'ng-select2';
import { CartComponent } from './pages/cart/cart.component';
import { PaymentSettingsComponent } from './pages/payment-settings/payment-settings.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environment';
import { RechargeStatusComponent } from './pages/recharge-status/recharge-status.component';


@NgModule({
  declarations: [
    SelfcareLayoutComponent,
    TopupComponent,
    SelfcareNavbarComponent,
    CartComponent,
    PaymentSettingsComponent,
    CheckoutComponent,
    RechargeStatusComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    SelfcareRoutingModule,
    NgSelect2Module,
    NgxStripeModule.forRoot(environment.stripePublishableKey),
  ]
})
export class SelfcareModule { }
