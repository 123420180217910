import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaymentIntent } from '@stripe/stripe-js';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/@core/services';
import { RechargeResult } from '../models/recharge-result';

@Injectable({
  providedIn: 'root'
})
export class RechargeService {

  constructor(
    private apiService: ApiService,
  ) { }

  createPaymentIntent(
    email: string,
  ): Observable<PaymentIntent> {
    return this.apiService.post('/payment-intent/for-cart-items', {
      email: email,
      platform: "web"
    });
  }

  validateRecharge(
    skuCode: string,
    sendValue: number,
    accountNo: string
  ): Observable<RechargeResult[]> {
    const body = {
      "sku_code": skuCode,
      "send_value": sendValue.toString(),
      "phone_number": accountNo,
    }
    return this.apiService.post('/validate-recharge', body);
  }

  recharge(
    email: string,
    paymentIntentId: string,
    firstName?: string,
    lastName?: string,
    billingAddress?: string,
    billingCity?: string,
    billingState?: string,
    billingZip?: string,
    billingCountry?: string
  ): Observable<RechargeResult[]> {
    return this.apiService.post('/recharge', {
      email: email,
      payment_intent_id: paymentIntentId,
      first_name: firstName ?? null,
      last_name: lastName ?? null,
      billing_address: billingAddress ?? null,
      billing_city: billingCity ?? null,
      billing_state: billingState ?? null,
      billing_zip: billingZip ?? null,
      billing_country: billingCountry ?? null,
    });
  }
}
