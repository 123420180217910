<!-- <div class="page-title-area item-bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Sign In</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Sign In</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="sign-in-area ptb-100">
    <div class="container">
        <div class="sign-in-form">
            <div class="sign-in-title">
                <h3>Verify your email address</h3>
                <p>A verification code was sent to your email address <b>{{ email }}</b>.</p>
            </div>

            <form novalidate="novalidate" [formGroup]="authForm" (ngSubmit)="submitForm()">

                <!-- begin::Alert error-->
                <ng-container *ngIf="error">
                    <div class="mb-10 alert alert-danger alert-dismissible fade show" role="alert">
                        <div class="alert-text">{{ error }}</div>
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                </ng-container>
                <!-- end::Alert error-->

                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group has-danger mb-3">
                            <input type="text" class="form-control" placeholder="Verification code" maxlength="4"
                                formControlName="code"
                                [ngClass]="{'is-invalid': (code.touched || isFormSubmitAttempted) && code.invalid}">
                            <div *ngIf="code.errors?.required" class="invalid-feedback">
                                This field is required.
                            </div>
                            <div *ngIf="code.errors?.minlength" class="invalid-feedback">
                                Invalid verification code entered.
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="send-btn">
                            <button *ngIf="!isSubmitting" type="submit" class="default-btn w-100">
                                Sign in
                            </button>
                            <button *ngIf="isSubmitting" class="default-btn w-100" disabled>
                                Verifying
                                <div class="spinner-border spinner-border-sm" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </button>
                        </div>
                        <br>
                        <span>Didn't receive a verification code? <a [routerLink]="" (click)="goBack()">Try
                                again!</a></span>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>