<section class="ptb-100">
    <div class="container">

        <ng-container *ngIf="loadingState !== loadingStates.retrievingItems">
            <ng-container *ngIf="cartItems?.items?.length || 0; else emptyElement">
                <div class="row gx-5">

                    <div class="col-12 col-lg-7">
                        <div id="plans">
                            <h2 class="fs-4 mb-4">Items in cart ({{ cartItems.items.length }})</h2>

                            <div class="plans row gy-4 gx-4">

                                <div *ngFor="let item of cartItems.items" class="col-12">
                                    <div class="plan">
                                        <div class="amount">{{ item.receiveAmount }} {{ item.receiveCurrencyIso }}
                                        </div>
                                        <div class="info">
                                            <h6 class="value fw-normal">Recharge for {{ item.phone }}</h6>
                                            <p class="title text-muted">
                                                Country: {{ item.country }} |
                                                Provider: {{ item.provider }}
                                            </p>
                                            <h6 class="mt-3">{{ item.price }} {{ item.sendCurrencyIso }}</h6>
                                        </div>
                                        <div class="button-wrapper align-self-start">
                                            <button type="button" class="btn-close" aria-label="Close"
                                                (click)="confirmDelete(item)"></button>
                                        </div>
                                    </div> <!-- /.plan -->
                                </div> <!-- /.col -->

                            </div>
                        </div> <!-- /#plans -->
                    </div>

                    <div class="col-12 col-lg-5 mt-5 mt-lg-0">
                        <div id="checkout-details">
                            <h2 class="fs-4 mb-4">Price details</h2>

                            <div class="checkout-details">
                                <table class="table text-muted">
                                    <tr>
                                        <td>Total items</td>
                                        <td class="text-end">{{ cartItems.items.length }}</td>
                                    </tr>
                                    <tr>
                                        <td>Total recharge amount</td>
                                        <td class="text-end">{{ cartItems.totalRechargeAmount }} USD</td>
                                    </tr>
                                    <tr>
                                        <td>Processing fee</td>
                                        <td class="text-end">{{ cartItems.totalServiceCharge }} USD</td>
                                    </tr>
                                </table>
                                <hr>
                                <table class="table mt-3">
                                    <tr>
                                        <td class="fs-6">Total amount</td>
                                        <td class="fs-6 text-end">{{ cartItems.totalAmount }} USD</td>
                                    </tr>
                                </table>
                                <a href="/selfcare/checkout" routerLink="/selfcare/checkout"
                                    class="default-btn w-100 mt-4">Checkout now</a>
                            </div>
                        </div> <!-- /#plans -->
                    </div>

                </div> <!-- /.row -->
            </ng-container>

            <ng-template #emptyElement>
                <div class="error">
                    <img src="assets/img/error.png" alt="">
                    <div class="info">
                        <h5 class="primary-message">Nothing in here!</h5>
                        <p class="secondary-message text-muted">No items have been added to cart yet. Please add a
                            recharge now.</p>
                    </div>
                    <div class="buttons">
                        <a href="/selfcare/topup" routerLink="/selfcare/topup" class="default-btn">Recharge now</a>
                    </div>
                </div>
            </ng-template>
        </ng-container>

    </div> <!-- /.container -->
</section>

<!-- Delete Modal -->
<div class="modal" id="delete-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Confirm delete</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p>Are you sure you want to remove this item from cart?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" data-bs-dismiss="modal"
                    (click)="cancelDelete">Cancel</button>
                <button type="button" class="btn btn-danger" (click)="deleteItem()">Yes</button>
            </div>
        </div>
    </div>
</div>

<ng-container [ngSwitch]="loadingState">
    <app-loader *ngSwitchCase="loadingStates.retrievingItems" primaryMessage="Fetching cart items"
        secondaryMessage="Please wait..."></app-loader>
    <app-loader *ngSwitchCase="loadingStates.removingItem" primaryMessage="Removing item"
        secondaryMessage="Please wait..."></app-loader>
    <app-loader *ngSwitchCase="loadingStates.other"></app-loader>
</ng-container>