import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeComponent } from './components/pages/home/home.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { AboutComponent } from './components/pages/about/about.component';
import { TeamComponent } from './components/pages/team/team.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { LoanCalculatorComponent } from './components/pages/loan-calculator/loan-calculator.component';
import { ApplyNowComponent } from './components/pages/apply-now/apply-now.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { ProjectsComponent } from './components/pages/projects/projects.component';
import { ProjectsDetailsComponent } from './components/pages/projects-details/projects-details.component';
import { SignInComponent } from './components/pages/sign-in/sign-in.component';
import { SignUpComponent } from './components/pages/sign-up/sign-up.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { FundingComponent } from './components/pages/funding/funding.component';
import { LoansComponent } from './components/pages/loans/loans.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';

// const routes: Routes = [
//     {path: '', component: HomeComponent},
//     {path: 'home-two', component: HomeOneComponent},
//     {path: 'home-three', component: HomeThreeComponent},
//     {path: 'about', component: AboutComponent},
//     {path: 'team', component: TeamComponent},
//     {path: 'faq', component: FaqComponent},
//     {path: 'gallery', component: GalleryComponent},
//     {path: 'loan-calculator', component: LoanCalculatorComponent},
//     {path: 'apply-now', component: ApplyNowComponent},
//     {path: 'testimonials', component: TestimonialsComponent},
//     {path: 'projects', component: ProjectsComponent},
//     {path: 'project-details', component: ProjectsDetailsComponent},
//     {path: 'sign-in', component: SignInComponent},
//     {path: 'sign-up', component: SignUpComponent},
//     {path: 'terms-conditions', component: TermsConditionsComponent},
//     {path: 'privacy-policy', component: PrivacyPolicyComponent},
//     {path: 'error', component: ErrorComponent},
//     {path: 'services', component: ServicesComponent},
//     {path: 'services-details', component: ServicesDetailsComponent},
//     {path: 'funding', component: FundingComponent},
//     {path: 'loans', component: LoansComponent},
//     {path: 'contact', component: ContactComponent},
//     {path: 'blog', component: BlogComponent},
//     {path: 'blog-details', component: BlogDetailsComponent},
//     {path: '**', component: ErrorComponent},
// ];

const routes: Routes = [
    {
        path: '**',
        component: ErrorComponent
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }