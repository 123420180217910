import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { UserService } from 'src/app/@core/services';
import { CartItem } from '../../models/cart-item';
import { CartItems } from '../../models/cart-items';
import { CartService } from '../../services/cart.service';

declare const jQuery: any;

enum LoadingState {
  none,
  retrievingItems,
  removingItem,
  other,
}

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  loadingStates = LoadingState;
  loadingState: LoadingState = LoadingState.none;
  error?: string;

  cartItems?: CartItems;

  deletingItem?: CartItem;

  constructor(
    private userService: UserService,
    private cartService: CartService,
    private toastrService: ToastrService,
  ) { }

  ngOnInit(): void {
    this.retrieveCartItems();
  }

  async retrieveCartItems() {
    this.loadingState = LoadingState.retrievingItems;
    this.error = undefined;

    const user = await this.userService.currentUser.pipe(first()).toPromise();

    try {
      this.cartItems = await this.cartService.retrieveAll(user.email).toPromise();
      this.loadingState = LoadingState.none;
    } catch (error) {
      console.error("Failed retrieving cart items: ", error);
      this.loadingState = LoadingState.none;
      this.error = "Failed retrieving cart items.";
    }
  }

  confirmDelete(item: CartItem) {
    this.deletingItem = item;
    jQuery('#delete-modal').show();
  }

  cancelDelete() {
    this.deletingItem = undefined;
    jQuery('#delete-modal').hide();
  }

  async deleteItem() {
    jQuery('#delete-modal').hide();

    if (!this.deletingItem) throw new Error('`deletingItem` not found.');

    this.loadingState = LoadingState.removingItem;
    this.error = undefined;

    try {
      await this.cartService.removeItem({
        email: this.deletingItem.email, phone: this.deletingItem.phone
      }).toPromise();
      await this.retrieveCartItems();
      this.toastrService.success("The item has been removed from cart.");
    } catch (error) {
      console.error("Failed removing cart item: ", error);
      this.loadingState = LoadingState.none;
      this.toastrService.error("Failed removing item from cart.");
    }
  }

}
