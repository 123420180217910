<div class="main-banner-area bg-two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="main-banner-content">
                    <span>Welcome, we are here for you!</span>
                    <h1>Start or grow your own business</h1>
                    <p>Every day, Africans in the diaspora send phone credit or internet data to their love ones in
                        Africa using 24-7 Topup app Instant Online Phone Top up and Internet Data To Africa</p>
                    <div class="banner-btn">
                        <a href="https://apps.apple.com/us/app/24-7-topup/id1572440714" class="default-btn p-0">
                            <img src="assets/img/download-appstore.png" alt="" style="width: 168px;">
                            <!-- View More<span></span> -->
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.topup_247"
                            class="default-btn p-0 ms-2">
                            <img src="assets/img/download-play.png" alt="" style="width: 168px;">
                            <!-- View More<span></span> -->
                        </a>
                    </div>
                </div>
                <div class="banner-social-buttons">
                    <ul>
                        <li><span>Follow us</span></li>
                        <li><a href="https://www.facebook.com/Topup247ng" target="_blank"><i
                                    class="flaticon-facebook"></i></a></li>
                        <li><a href="https://twitter.com/247topup" target="_blank"><i class="flaticon-twitter"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="partner-area bg-color ptb-100">
    <div class="container">
        <div class="partner-slider owl-carousel owl-theme">
            <div class="partner-item">
                <img src="assets/img/providers/africell.jpg" alt="image">
            </div>
            <div class="partner-item">
                <img src="assets/img/providers/airtel.png" alt="image">
            </div>
            <div class="partner-item">
                <img src="assets/img/providers/moov.jpeg" alt="image">
            </div>
            <div class="partner-item">
                <img src="assets/img/providers/mtn.jpeg" alt="image">
            </div>
            <div class="partner-item">
                <img src="assets/img/providers/qcells.jpeg" alt="image">
            </div>
            <div class="partner-item">
                <img src="assets/img/providers/tigo.jpeg" alt="image">
            </div>
            <div class="partner-item">
                <img src="assets/img/providers/vodacom.png" alt="image">
            </div>
        </div>
    </div>
</div>

<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-wrap">
                    <img src="assets/img/about/image3.png" alt="image">
                    <div class="about-shape">
                        <div class="text">
                            <h3>25</h3>
                            <span>Years Of Experience</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-content">
                    <span>About company</span>
                    <h3>24-7topup is a product of BAAJA Technologies LLC, a 100% African owned business.</h3>
                    <p>We have been delivering online top up and internet data to countries in Africa since 2019. We
                        work diligently with our mobile communication partners to ensure that you keep talking and stay
                        connected with love ones in Africa. As Africans we understand your needs.</p>
                    <div class="about-btn">
                        <a routerLink="/auth/sign-up" class="default-btn">Register now<span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="choose-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="choose-title">
                    <span>Transparent process</span>
                    <h2>Why people choose us</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua.</p>
                </div>

                <div class="choose-image">
                    <img src="assets/img/choose.png" alt="image">
                    <a href="https://www.youtube.com/watch?v=qaHWDmFtBl0" class="video-btn popup-youtube"><i
                            class="flaticon-play-button"></i></a>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="process-item bg-top1">
                            <div class="icon">
                                <i class="flaticon-guarantee"></i>
                            </div>
                            <h3>Guarantee</h3>
                            <p>Our System has a 99.9% uptime. You can process your phone top up and internet data
                                transaction with ease at anytime, anywhere. We also offer batch processing so you can
                                send top up or internet data to 3 different numbers in the same transaction.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="process-item bg-top3">
                            <div class="icon">
                                <i class="flaticon-reliability"></i>
                            </div>
                            <h3>Reliability</h3>
                            <p>We are passionate about our work and value your time and money. Therefore, customer
                                service and reliability is front and center in our operational model. Our customers are
                                very happy as they find us to be very professional and reliable.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="process-item bg-top2">
                            <div class="icon">
                                <i class="flaticon-speed"></i>
                            </div>
                            <h3>Security</h3>
                            <p>Data confidentiality, integrity and availability is paramount in our daily operations.
                                Data transmission travels through a secure encrypted channel. We have also partnered
                                with stripe for a secure payment processing experience.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="process-item bg-top4">
                            <div class="icon">
                                <i class="flaticon-user-experience"></i>
                            </div>
                            <h3>Affordability</h3>
                            <p>Our rates for phone credit and internet data is very well priced and highly competitive.
                                In most cases you will find us as one of the most affordable top up provider. We also
                                offer special bonuses to our customers from time to time.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="deserve-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Funding circles</span>
            <h2>You deserve a better phone top up and internet data service</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. </p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="deserve-item">
                    <h3>Quick and easy steps to follow.</h3>

                    <div class="deserve-content">
                        <span>1</span>
                        <h4>Get the app</h4>
                        <p>Install the app from Play store or App store.</p>
                        <div class="mt-3">
                            <a href="https://apps.apple.com/us/app/24-7-topup/id1572440714" class="default-btn p-0">
                                <img src="assets/img/download-appstore.png" alt="" style="width: 130px;">
                                <!-- View More<span></span> -->
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.topup_247"
                                class="default-btn p-0 ms-2">
                                <img src="assets/img/download-play.png" alt="" style="width: 130px;">
                                <!-- View More<span></span> -->
                            </a>
                        </div>
                    </div>
                    <div class="deserve-content">
                        <span>2</span>
                        <h4>Registration</h4>
                        <p>Click on new user to register your account.</p>
                    </div>
                    <div class="deserve-content">
                        <span>3</span>
                        <h4>Setup</h4>
                        <p>After registration is complete, click on account, payment settings to save your payment details.</p>
                    </div>
                    <div class="deserve-content">
                        <span>4</span>
                        <h4>Recharge</h4>
                        <p>Click on Send top up or internet data to start your transaction.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <img src="assets/img/company2-1.png" alt="">
            </div>
        </div>
    </div>
</section>

<section class="solution-area pb-100">
    <div class="container">
        <a routerLink="/faq" class="solution-item display-block">
            <div class="row align-items-center">
                <div class="col-lg-7">
                    <div class="content">
                        <h3>Click here for answers to your questions</h3>
                    </div>
                </div>

                <div class="col-lg-5">
                    <div class="solution-info">
                        <i class="flaticon-telephone"></i>
                        <h3><a href="tel:15143125678">+1 (514) 312-5678</a></h3>
                    </div>
                </div>
            </div>
        </a>
    </div>
</section>

<section class="best-services-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Knowledge of the market</span>
            <h2>Check out our online market for Sierra Leone</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. </p>
        </div>

        <div class="best-services-slider">
            <div id="thumbs" class="owl-carousel owl-theme">
                <div class="tabs-item">
                    <!-- <a href="#">
                        <i class="flaticon-agriculture"></i>
                        <span>Agricultural loan</span>
                    </a> -->
                </div>

                <div class="tabs-item">
                    <!-- <a href="#">
                        <i class="flaticon-personal"></i>
                        <span>Personal loan</span>
                    </a> -->
                </div>

                <div class="tabs-item">
                    <!-- <a href="#">
                        <i class="flaticon-loan-1"></i>
                        <span>Business loan</span>
                    </a> -->
                </div>

                <div class="tabs-item">
                    <!-- <a href="#">
                        <i class="flaticon-scholarship"></i>
                        <span>Education loan</span>
                    </a> -->
                </div>

                <div class="tabs-item">
                    <!-- <a href="#">
                        <i class="flaticon-loan-2"></i>
                        <span>House loan</span>
                    </a> -->
                </div>
            </div>

            <div id="best-services" class="owl-carousel owl-theme">
                <div class="services-tabs-item">
                    <a href="https://www.247bigmarket.com/" target="_blank">
                        <img src="https://247bigmarket.com/wp-content/uploads/2020/10/Shop-from-Anywhere-Family-pick-up-in-sierra-leone-1.png" alt="">
                    </a>
                </div>
                <div class="services-tabs-item">
                    <a href="https://www.247bigmarket.com/" target="_blank">
                        <img src="https://247bigmarket.com/wp-content/uploads/2020/10/Copy-of-pick-up-locations-1.png" alt="">
                    </a>
                </div>
                <div class="services-tabs-item">
                    <a href="https://www.247bigmarket.com/" target="_blank">
                        <img src="https://247bigmarket.com/wp-content/uploads/2020/10/Goodies.png" alt="">
                    </a>
                </div>
                <div class="services-tabs-item">
                    <a href="https://www.247bigmarket.com/" target="_blank">
                        <img src="https://247bigmarket.com/wp-content/uploads/2020/10/rajus.png" alt="">
                    </a>
                </div>
                <div class="services-tabs-item">
                    <a href="https://www.247bigmarket.com/" target="_blank">
                        <img src="https://247bigmarket.com/wp-content/uploads/2020/10/KENEMA-1.png" alt="">
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="clients-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Clients words</span>
            <h2>What our clients say</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. </p>
        </div>

        <div class="clients-slider owl-carousel owl-theme">
            <div class="clients-item">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="clients-info-text">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.
                                Risus commodo viverra maecenas accumsan lacus vel facilisis.”</p>
                            <h3>Debra C. Cowen</h3>
                            <span>Founder</span>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="clients-image">
                            <img src="assets/img/clients/man.png" alt="image">
                            <div class="icon-1">
                                <i class="flaticon-right-quote"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="clients-item">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="clients-info-text">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.
                                Risus commodo viverra maecenas accumsan lacus vel facilisis.”</p>
                            <h3>Debra C. Cowen</h3>
                            <span>Founder</span>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="clients-image">
                            <img src="assets/img/clients/man.png" alt="image">
                            <div class="icon-1">
                                <i class="flaticon-right-quote"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="clients-item">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="clients-info-text">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.
                                Risus commodo viverra maecenas accumsan lacus vel facilisis.”</p>
                            <h3>Debra C. Cowen</h3>
                            <span>Founder</span>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="clients-image">
                            <img src="assets/img/clients/man.png" alt="image">
                            <div class="icon-1">
                                <i class="flaticon-right-quote"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Expert People</span>
            <h2>Global leadership team</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/image1.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Jerome Le Luel</h3>
                        <span>Managing Director</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/image2.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Andrew Learoyd</h3>
                        <span>Chief Executive Officer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/image3.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Harry Nelis</h3>
                        <span>Technology Officer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/image4.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Lucy Vernall</h3>
                        <span>Chief Financial Officer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Recent news</span>
            <h2>Success story posts</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. </p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="blog-item">
                    <div class="image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/image1.jpg" alt="image">
                        </a>
                    </div>

                    <div class="content">
                        <span>July 05, 2020</span>
                        <h3><a routerLink="/blog-details">Financing loans available to small businesses</a></h3>
                        <a routerLink="/blog-details" class="blog-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-item">
                    <div class="image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/image2.jpg" alt="image">
                        </a>
                    </div>

                    <div class="content">
                        <span>July 04, 2020</span>
                        <h3><a routerLink="/blog-details">5 Ways you can prepare your business for success</a></h3>
                        <a routerLink="/blog-details" class="blog-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="blog-item">
                    <div class="image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/image3.jpg" alt="image">
                        </a>
                    </div>

                    <div class="content">
                        <span>July 03, 2020</span>
                        <h3><a routerLink="/blog-details">Tips for saving money and better guide for investment</a></h3>
                        <a routerLink="/blog-details" class="blog-btn">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->