<!-- <div class="page-title-area item-bg-4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Sign Up</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Sign Up</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="signup-area ptb-100">
    <div class="container">
        <div class="signup-form">
            <h3>Create your Account</h3>
            <form novalidate="novalidate" [formGroup]="form" (ngSubmit)="submit()">

                <!-- begin::Alert error-->
                <ng-container *ngIf="error">
                    <div class="mb-10 alert alert-danger alert-dismissible fade show" role="alert">
                        <div class="alert-text">{{ error }}</div>
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                </ng-container>
                <!-- end::Alert error-->

                <div class="row">
                    <div class="col-12">
                        <div class="form-group mb-3">
                            <input type="text" class="form-control" placeholder="Name" formControlName="name"
                                [ngClass]="{'is-invalid': (name.touched || isFormSubmitAttempted) && name.invalid}">
                            <div *ngIf="name.errors?.required" class="invalid-feedback">
                                This field is required.
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group mb-3">
                            <input type="email" class="form-control" placeholder="Email address" formControlName="email"
                                [ngClass]="{'is-invalid': (email.touched || isFormSubmitAttempted) && email.invalid}">
                            <div *ngIf="email.errors?.required" class="invalid-feedback">
                                This field is required.
                            </div>
                            <div *ngIf="email.errors?.email" class="invalid-feedback">
                                Invalid email address entered.
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group mb-3">
                            <ng-select2 formControlName="country" placeholder="Country"
                                [options]="countrySelect2Options" (valueChanged)="handleCountryUpdate($event)"
                                [ngClass]="{'is-invalid': (country.touched || isFormSubmitAttempted) && country.invalid}">
                            </ng-select2>
                            <div *ngIf="country.errors?.required" class="invalid-feedback">
                                This field is required.
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group mb-3">
                            <input type="tel" class="form-control" placeholder="Phone number (without country code)"
                                formControlName="phone"
                                [ngClass]="{'is-invalid': (phone.touched || isFormSubmitAttempted) && phone.invalid}">
                            <div *ngIf="phone.errors?.required" class="invalid-feedback">
                                This field is required.
                            </div>
                            <div *ngIf="phone.errors?.invalidPhoneNumber" class="invalid-feedback">
                                {{ phone.errors.invalidPhoneNumber.message }}
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group mb-3">
                            <input type="text" class="form-control" placeholder="Address" formControlName="address"
                                [ngClass]="{'is-invalid': (address.touched || isFormSubmitAttempted) && address.invalid}">
                            <div *ngIf="address.errors?.required" class="invalid-feedback">
                                This field is required.
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group mb-3">
                            <input type="text" class="form-control" placeholder="City" formControlName="city"
                                [ngClass]="{'is-invalid': (city.touched || isFormSubmitAttempted) && city.invalid}">
                            <div *ngIf="city.errors?.required" class="invalid-feedback">
                                This field is required.
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group mb-3">
                            <input type="text" class="form-control" placeholder="State" formControlName="state"
                                [ngClass]="{'is-invalid': (state.touched || isFormSubmitAttempted) && state.invalid}">
                            <div *ngIf="state.errors?.required" class="invalid-feedback">
                                This field is required.
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group mb-3">
                            <input type="text" class="form-control" placeholder="Zip/postal code"
                                formControlName="postalCode"
                                [ngClass]="{'is-invalid': (postalCode.touched || isFormSubmitAttempted) && postalCode.invalid}">
                            <div *ngIf="postalCode.errors?.required" class="invalid-feedback">
                                This field is required.
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <!-- <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="checkme"> -->
                        <p>By continuing you allow us to use
                            your information in accordance with our Terms & Conditions and Privacy Notice.</p>
                        <!-- </div> -->
                    </div>

                    <div class="col-12">
                        <div class="send-btn">
                            <button *ngIf="!isSubmitting" type="submit" class="default-btn w-100">
                                Sign up now
                            </button>
                            <button *ngIf="isSubmitting" class="default-btn w-100" disabled>
                                Signing up
                                <div class="spinner-border spinner-border-sm" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </button>
                        </div>
                        <br>
                        <span>Already a registered user? <a routerLink="/auth/sign-in" href="/auth/sign-in">Signin</a>
                            now!</span>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>