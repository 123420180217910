import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { SharedModule } from 'src/app/@shared';
import { SignInComponent } from './sign-in/sign-in.component';
import { VerifyComponent } from './verify/verify.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { AuthLayoutComponent } from './components/auth-layout/auth-layout.component';
import { NgSelect2Module } from 'ng-select2';


@NgModule({
  imports: [
    SharedModule,
    AuthRoutingModule,
    NgSelect2Module,
  ],
  declarations: [
    SignInComponent,
    VerifyComponent,
    SignUpComponent,
    AuthLayoutComponent,
  ],
})
export class AuthModule { }
