import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/@core/services';
import { PaymentMethod } from '../models/payment-method';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodService {

  constructor(
    private apiService: ApiService,
  ) { }

  retrieveAll(email: string): Observable<PaymentMethod[]> {
    return this.apiService.get('/payment-methods', new HttpParams({
      fromObject: { email: email }
    }));
  }
}
