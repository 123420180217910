import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
// import { NavbarComponent } from './components/common/navbar/navbar.component';
// import { FooterComponent } from './components/common/footer/footer.component';
import { HomeComponent } from './components/pages/home/home.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { AboutComponent } from './components/pages/about/about.component';
import { TeamComponent } from './components/pages/team/team.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { LoanCalculatorComponent } from './components/pages/loan-calculator/loan-calculator.component';
import { ApplyNowComponent } from './components/pages/apply-now/apply-now.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { ProjectsComponent } from './components/pages/projects/projects.component';
import { ProjectsDetailsComponent } from './components/pages/projects-details/projects-details.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { FundingComponent } from './components/pages/funding/funding.component';
import { LoansComponent } from './components/pages/loans/loans.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { HomeModule } from './modules/home/home.module';
import { SharedModule } from './@shared';
import { CoreModule } from './@core/core.module';
import { AuthModule } from './modules/auth/auth.module';
import { SelfcareModule } from './modules/selfcare/selfcare.module';

@NgModule({
  declarations: [
    AppComponent,
    HomeOneComponent,
    // NavbarComponent,
    // FooterComponent,
    HomeComponent,
    HomeThreeComponent,
    ContactComponent,
    GalleryComponent,
    AboutComponent,
    TeamComponent,
    FaqComponent,
    LoanCalculatorComponent,
    ApplyNowComponent,
    TestimonialsComponent,
    ProjectsComponent,
    ProjectsDetailsComponent,
    // SignInComponent,
    // SignUpComponent,
    // TermsConditionsComponent,
    // PrivacyPolicyComponent,
    ErrorComponent,
    ServicesComponent,
    ServicesDetailsComponent,
    FundingComponent,
    LoansComponent,
    BlogComponent,
    BlogDetailsComponent,
  ],
  imports: [
    BrowserModule,
    CoreModule,
    SharedModule,
    HomeModule,
    AuthModule,
    SelfcareModule,
    AppRoutingModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
