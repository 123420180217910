import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { UserService } from 'src/app/@core/services';
import { CartService } from '../../services/cart.service';

@Component({
  selector: 'selfcare-navbar',
  templateUrl: './selfcare-navbar.component.html',
  styleUrls: ['./selfcare-navbar.component.scss']
})
export class SelfcareNavbarComponent implements OnInit {

  constructor(
    private router: Router,
    private userService: UserService,
    private cartService: CartService
  ) { }

  ngOnInit(): void {
  }

  get cartItemsCount() {
    return this.cartService.cartItems.pipe(map(value => value?.items?.length || 0));
  }

  signOut() {
    this.userService.purgeAuth();
    this.router.navigateByUrl('/');
  }
}
