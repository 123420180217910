<section class="ptb-100">
    <div class="container">

        <div>
            <h2 class="fs-1">Here's your recharge status</h2>
            <h6 class="fw-normal text-muted mt-2">{{ successCount }} of {{ rechargeResults.length }} recharges have been successful</h6>
        </div>

        <div class="mt-5">
            <table class="table table-striped table-hover">
                <tr>
                    <th>Phone number</th>
                    <th>Send value</th>
                    <th>Status</th>
                    <th>Notes</th>
                </tr>
                <tr *ngFor="let result of rechargeResults">
                    <td>{{ result.phone }}</td>
                    <td>{{ result.receiveValue }} {{ result.receiveCurrency }}</td>
                    <td>
                        <span class="badge" [ngClass]="{'bg-success': result.status === 'Complete', 'bg-danger': result.status !== 'Complete'}">{{ result.status }}</span>
                    </td>
                    <td>{{ result.errorCode || '--' }}</td>
                </tr>
            </table>
        </div>

        <div class="mt-5">
            <a href="/selfcare/topup" routerLink="/selfcare/topup" class="default-btn">Send another topup or data</a>
        </div>

    </div> <!-- /.container -->
</section>