import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { StripeCardElementChangeEvent, StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { StripeCardComponent, StripeCardNumberComponent, StripeService } from 'ngx-stripe';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { UserService } from 'src/app/@core/services';
import { environment } from 'src/environments/environment';
import { CartItems } from '../../models/cart-items';
import { PaymentMethod } from '../../models/payment-method';
import { CartService } from '../../services/cart.service';
import { PaymentMethodService } from '../../services/payment-method.service';
import { RechargeService } from '../../services/recharge.service';
import { Options } from 'select2';
import { Select2OptionData } from 'ng-select2';

declare const jQuery: any;

enum LoadingState {
  none,
  retrievingItems,
  retrievingPaymentMethods,
  processingPayment,
  other,
}

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  loadingStates = LoadingState;
  loadingState: LoadingState = LoadingState.none;
  error?: string;

  cartItems?: CartItems;
  paymentMethods: PaymentMethod[] = [];

  activePaymentMethod?: string;

  isNewCardValid: boolean = false;
  @ViewChild(StripeCardComponent) card: StripeCardComponent;


  countrySelect2Options: Options;
  newCardForm: FormGroup;

  cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#b1b6bb',
        color: '#444444',
        fontWeight: '400',
        fontFamily: 'sans-serif',
        fontSize: '16px',
        '::placeholder': {
          color: '#b1b6bb'
        },
        lineHeight: '38px',
        backgroundColor: '#ffffff',
      }
    }
  };
  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };

  constructor(
    private router: Router,
    private userService: UserService,
    private cartService: CartService,
    private paymentMethodService: PaymentMethodService,
    private stripeService: StripeService,
    private rechargeService: RechargeService,
    private toastrService: ToastrService,
    private fb: FormBuilder
  ) {
    this.countrySelect2Options = {
      templateResult: this.templateResult,
      templateSelection: this.templateResult,
      ajax: {
        url: `${environment.apiEndpoint}/countries-autocomplete`,
        dataType: 'json',
        processResults: data => {
          const resultData = data.map(value => {
            return {
              id: value.code,
              text: `${value.name} (+${value.dial_code})`,
              additional: {
                image: value.flag
              }
            };
          });
          return {
            results: resultData
          };
        }
      },
    }

    this.newCardForm = this.fb.group({
      'name': ['', [Validators.required]],
      'address': ['', Validators.required],
      'city': ['', Validators.required],
      'state': ['', Validators.required],
      'postalCode': ['', [Validators.required]],
      'country': ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.initialize();
  }

  handleActivePaymentMethodUpdate() {
    this.isNewCardValid = false;
    this.newCardForm.reset();
  }

  templateResult(state: Select2OptionData) {
    if (!state.id) {
      return state.text;
    }

    if (!state?.additional?.image) {
      return state.text;
    } else {
      const option = jQuery(
        '<span><img class="select2-option-image" src="' + state.additional.image + '" /> ' + state.text + '</span>'
      );
      return option;
    }
  }

  get name() {
    return this.newCardForm.get('name');
  }

  get address() {
    return this.newCardForm.get('address');
  }

  get city() {
    return this.newCardForm.get('city');
  }

  get state() {
    return this.newCardForm.get('state');
  }

  get postalCode() {
    return this.newCardForm.get('postalCode');
  }

  get country() {
    return this.newCardForm.get('country');
  }

  async initialize() {
    this.loadingState = LoadingState.retrievingItems;
    this.error = undefined;

    const user = await this.userService.currentUser.pipe(first()).toPromise();

    try {
      this.cartItems = await this.cartService.retrieveAll(user.email).toPromise();

      if (!this.cartItems.items.length) {
        this.router.navigateByUrl("/selfcare/cart");
        return;
      }
    } catch (error) {
      console.error("Failed retrieving cart items: ", error);
      this.loadingState = LoadingState.none;
      this.error = "Failed retrieving cart items.";
    }

    this.loadingState = LoadingState.retrievingPaymentMethods;

    try {
      this.paymentMethods = await this.paymentMethodService.retrieveAll(user.email).toPromise();
    } catch (error) {
      console.error("Failed retrieving payment methods: ", error);
      this.loadingState = LoadingState.none;
      this.error = "Failed retrieving cart items.";
    }

    this.loadingState = LoadingState.none;
  }

  onCardInputChange(event: StripeCardElementChangeEvent) {
    this.isNewCardValid = event.complete;
  }

  async payWithCard(isNewCard: boolean) {
    this.newCardForm.markAsTouched();
    this.newCardForm.updateValueAndValidity();

    if (isNewCard && !(this.newCardForm.valid && this.isNewCardValid)) return;

    this.loadingState = LoadingState.processingPayment;
    this.error = undefined;

    const user = await this.userService.currentUser.pipe(first()).toPromise();

    try {
      let paymentIntent = await this.rechargeService.createPaymentIntent(user.email).toPromise();

      if (paymentIntent.client_secret == null) throw new Error("Error paying.");

      let firstName: string;
      let lastName: string;
      let billingAddress: string;
      let billingCity: string;
      let billingState: string;
      let billingZip: string;
      let billingCountry: string;

      if (paymentIntent.status === 'requires_payment_method'
        || paymentIntent.status === 'requires_confirmation') {

        const paymentMethodParam = isNewCard
          ? {
            card: this.card.element,
            billing_details: {
              name: (this.name.value as string).replace(/\s+/g, " "),
              address: {
                country: this.country.value,
                state: this.state.value,
                city: this.city.value,
                postal_code: this.postalCode.value,
                line1: this.address.value,
              }
            }
          }
          : this.activePaymentMethod;

        const paymentIntentResult = await this.stripeService
          .confirmCardPayment(paymentIntent.client_secret, {
            payment_method: paymentMethodParam
          }).toPromise();

        if (paymentIntentResult.error) {
          throw new Error(paymentIntentResult.error.message);
        }

        paymentIntent = paymentIntentResult.paymentIntent;

        if (isNewCard) {
          const nameComponents = ((paymentMethodParam as any).billing_details.name as string).split(" ");
          firstName = nameComponents[0];
          lastName = nameComponents.length > 1
            ? nameComponents.slice(1).join(" ")
            : undefined;
          billingAddress = (paymentMethodParam as any).billing_details.address.address
          billingCity = (paymentMethodParam as any).billing_details.address.city;
          billingState = (paymentMethodParam as any).billing_details.address.state;
          billingZip = (paymentMethodParam as any).billing_details.address.zip;
          billingCountry = (paymentMethodParam as any).billing_details.address.country;
        } else {
          const paymentMethod = this.paymentMethods.find(e => e.id === this.activePaymentMethod);
          const nameComponents = paymentMethod.billing_details.name.split(" ");
          firstName = nameComponents[0];
          lastName = nameComponents.length > 1
            ? nameComponents.slice(1).join(" ")
            : undefined;
          billingAddress = paymentMethod.billing_details.address.line1;
          billingCity = paymentMethod.billing_details.address.city;
          billingState = paymentMethod.billing_details.address.state;
          billingZip = paymentMethod.billing_details.address.postal_code;
          billingCountry = paymentMethod.billing_details.address.country;
        }
      }

      // TODO: uncomment
      const rechargeResults = await this.rechargeService.recharge(
        user.email,
        paymentIntent.id,
        firstName,
        lastName,
        billingAddress,
        billingCity,
        billingState,
        billingZip,
        billingCountry
      ).toPromise();

      await this.cartService.retrieveAll(user.email).toPromise()
        .catch(error => console.error("Failed retriving cart items"));

      this.router.navigateByUrl("/selfcare/recharge-status", {
        state: { rechargeResults }
      });

    } catch (error) {
      console.error("Failed paying: ", error);
      this.loadingState = LoadingState.none;
      this.toastrService.error(error.toString());
    }
  }

}
