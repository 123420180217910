<div class="page-title-area item-bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Apply now</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Apply now</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="apply-area ptb-100">
    <div class="container">
        <div class="apply-title">
            <h3>Loan Application</h3>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <div class="apply-form">
                    <div class="form-group mb-3">
                        <label>Desired Loan Amount*</label>
                        <input type="text" class="form-control">
                    </div>

                    <div class="form-group mb-3">
                        <label>Firstname</label>
                        <input type="text" class="form-control">
                    </div>

                    <div class="form-group mb-3">
                        <label>Date of Birth</label>
                        <input type="text" class="form-control">
                    </div>

                    <div class="form-group mb-3">
                        <label>Address*</label>
                        <input type="text" class="form-control">
                    </div>

                    <div class="form-group mb-3">
                        <label>Email Address*</label>
                        <input type="text" class="form-control">
                    </div>

                    <div class="form-group mb-3">
                        <label>Phone Number*</label>
                        <input type="text" class="form-control">
                    </div>

                    <div class="form-group mb-3">
                        <label>Designation</label>
                        <input type="text" class="form-control">
                    </div>

                    <div class="form-group mb-3">
                        <label>Purpose of Loan*</label>
                        <input type="text" class="form-control">
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="apply-form">
                    <div class="form-group mb-3">
                        <label>Annual Income*</label>
                        <input type="text" class="form-control">
                    </div>

                    <div class="form-group mb-3">
                        <label>Lastname*</label>
                        <input type="text" class="form-control">
                    </div>

                    <div class="form-group mb-3">
                        <label>Gender</label>
                        <div class="select-box">
                            <select class="form-control">
                                <option value="5">Male</option>
                                <option value="1">Female</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group mb-3">
                        <label>Country*</label>
                        <div class="select-box">
                            <select class="form-control">
                                <option value="5">United Arab Emirates</option>
                                <option value="1">China</option>
                                <option value="2">United Kingdom</option>
                                <option value="0">Germany</option>
                                <option value="3">France</option>
                                <option value="4">Japan</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group mb-3">
                        <label>Alternative Email Address*</label>
                        <input type="text" class="form-control">
                    </div>

                    <div class="form-group mb-3">
                        <label>Company Name</label>
                        <input type="text" class="form-control">
                    </div>

                    <div class="form-group mb-3">
                        <label>Company Address</label>
                        <input type="text" class="form-control">
                    </div>

                    <div class="form-group mb-3">
                        <label>Choose here</label>
                        <div class="select-box">
                            <select class="form-control">
                                <option value="5">Agricultural Loan</option>
                                <option value="1">Business Loan</option>
                                <option value="2">House Loan</option>
                                <option value="0">Personal Loan</option>
                                <option value="3">Education Loan</option>
                                <option value="4">Payday Loan</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="apply-btn">
            <a routerLink="/apply-now" class="default-btn">Apply Now<span></span></a>
        </div>
    </div>
</section>