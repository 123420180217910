import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { ApiService } from 'src/app/@core/services';
import { CartItem } from '../models/cart-item';
import { CartItems } from '../models/cart-items';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private cartItemsSubject = new BehaviorSubject<CartItems>(undefined);
  public cartItems = this.cartItemsSubject.asObservable().pipe(first());

  constructor(
    private apiService: ApiService,
  ) {
    // 
  }

  retrieveAll(email: string): Observable<CartItems> {
    return this.apiService.post('/getcart', {}, new HttpParams({
      fromObject: { email: email }
    })).pipe(map(value => {
      const items = value.data.map(item => <CartItem>{
        id: item.id,
        email: item.email,
        phone: item.phone,
        country: item.country,
        provider: item.provider,
        price: Number.parseFloat(item.price),
        isCustom: item.isCustom == true,
        skuCode: item.SkuCode,
        sendCurrencyIso: item.SendCurrencyIso,
        countryIso: item.CountryIso,
        receiveCurrencyIso: item.ReceivedCurrencyIso,
        receiveAmount: item.ReceivedAmount,
        createdAt: new Date(item.created_at)
      });

      const cartItems = <CartItems>{
        items: items,
        totalRechargeAmount: value.totalRechargeAmount,
        totalServiceCharge: value.totalServiceCharge,
        totalAmount: value.totalAmount,
      };

      this.cartItemsSubject.next(cartItems);
      return cartItems;
    }));
  }

  addItem(params: {
    email: string,
    price: number,
    phone: string,
    country: string,
    provider: string,
    isCustom: boolean,
    skuCode: string,
    sendCurrencyIso: string,
    countryIso: string,
    receiveCurrencyIso: string,
    receiveAmount: number,
  }): Observable<void> {
    const body = {
      email: params.email,
      price: `${params.price}`,
      phone: params.phone,
      country: params.country,
      provider: params.provider,
      isCustom: `${params.isCustom}`,
      SkuCode: params.skuCode,
      SendCurrencyIso: params.sendCurrencyIso,
      CountryIso: params.countryIso,
      ReceivedCurrencyIso: params.receiveCurrencyIso,
      ReceivedAmount: `${params.receiveAmount}`,
    };
    return this.apiService.post('/addtocart', body);
  }

  removeItem(params: {
    email: string,
    phone: string,
  }): Observable<void> {
    const body = {
      email: params.email,
      phone: params.phone,
    };
    return this.apiService.post('/removeitem', body);
  }
}
