<div class="page-title-area item-bg-6">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Privacy Policy</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Privacy Policy</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="terms-of-condition-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="terms-of-condition-content">
                    <img src="assets/img/privacy-policy.jpg" alt="image">
                    <h3>Welcome to Topli Privacy Policy</h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                    <h3>Welcome to Topli Privacy Policy</h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                    <h3>Welcome to Topli Privacy Policy</h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                    <h3>Welcome to Topli Privacy Policy</h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="terms-of-condition-sidebar">
                    <ul>
                        <li><a routerLink="/services-details">Agricultural Loan</a></li>
                        <li><a routerLink="/services-details">Business Loan</a></li>
                        <li><a routerLink="/services-details">House Loan</a></li>
                        <li><a routerLink="/services-details">Personal Loan</a></li>
                        <li><a routerLink="/services-details">Education Loan</a></li>
                        <li><a routerLink="/services-details">Payday Loan</a></li>
                        <li><a routerLink="/services-details">Vehicle loan</a></li>
                        <li><a routerLink="/services-details">Medical loan</a></li>
                        <li><a routerLink="/services-details">StartUp loan</a></li>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/terms-conditions">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>