import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/@core/services';
import { Plan } from '../models/plan';

@Injectable({
  providedIn: 'root'
})
export class PlanService {

  constructor(
    private apiService: ApiService
  ) {
    // 
  }

  retrieveAll(providerCode: string): Observable<Plan[]> {
    return this.apiService.get('/plans', new HttpParams({
      fromObject: { providerCode }
    }));
  }
}
