<div class="page-title-area item-bg-6">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Privacy Policy</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Privacy Policy</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="terms-of-condition-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="terms-of-condition-content">
                    <p>Your privacy is critically important to us.</p>
                    <p>It is 24-7Topup’s policy to respect your privacy regarding any information we may collect
                        while operating our website. This Privacy Policy applies to https://247topup.com
                        (hereinafter, "us", "we", or "https://247topup.com"). We respect your privacy and are
                        committed to protecting personally identifiable information you may provide us through the
                        Website. We have adopted this privacy policy ("Privacy Policy") to explain what information
                        may be collected on our Website, how we use this information, and under what circumstances
                        we may disclose the information to third parties. This Privacy Policy applies only to
                        information we collect through the Website and does not apply to our collection of
                        information from other sources.This Privacy Policy, together with the Terms and conditions
                        posted on our Website, set forth the general rules and policies governing your use of our
                        Website. Depending on your activities when visiting our Website, you may be required to
                        agree to additional terms and conditions.</p>
                    <h4 class="mt-5">Website Visitors</h4>
                    <p>Like most website operators, 24-7Topup collects non-personally-identifying information of the
                        sort that web browsers and servers typically make available, such as the browser type,
                        language preference, referring site, and the date and time of each visitor request.
                        24-7Topup’s purpose in collecting non-personally identifying information is to better
                        understand how 24-7Topup’s visitors use its website. From time to time, 24-7Topup may
                        release non-personally-identifying information in the aggregate, e.g., by publishing a
                        report on trends in the usage of its website.
                        24-7Topup also collects potentially personally-identifying information like Internet
                        Protocol (IP) addresses for logged in users and for users leaving comments on
                        https://247topup.com blog posts. 24-7Topup only discloses logged in user and commenter IP
                        addresses under the same circumstances that it uses and discloses personally-identifying
                        information as described below.</p>
                    <h4 class="mt-5">Gathering of Personally-Identifying Information</h4>
                    <p>Certain visitors to 24-7Topup’s websites choose to interact with 24-7Topup in ways that
                        require 24-7Topup to gather personally-identifying information. The amount and type of
                        information that 24-7Topup gathers depends on the nature of the interaction. For example, we
                        ask visitors who sign up for a blog at https://247topup.com to provide a username and email
                        address.</p>
                    <h4 class="mt-5">Security</h4>
                    <p>The security of your Personal Information is important to us, but remember that no method of
                        transmission over the Internet, or method of electronic storage is 100% secure. While we
                        strive to use commercially acceptable means to protect your Personal Information, we cannot
                        guarantee its absolute security.</p>
                    <h4 class="mt-5">Advertisements</h4>
                    <p>Ads appearing on our website may be delivered to users by advertising partners, who may set
                        cookies. These cookies allow the ad server to recognize your computer each time they send
                        you an online advertisement to compile information about you or others who use your
                        computer. This information allows ad networks to, among other things, deliver targeted
                        advertisements that they believe will be of most interest to you. This Privacy Policy covers
                        the use of cookies by 24-7Topup and does not cover the use of cookies by any advertisers.
                    </p>
                    <h4 class="mt-5">Links To External Sites</h4>
                    <p>Our Service may contain links to external sites that are not operated by us. If you click on
                        a third party link, you will be directed to that third party's site. We strongly advise you
                        to review the Privacy Policy and terms and conditions of every site you visit.
                        We have no control over, and assume no responsibility for the content, privacy policies or
                        practices of any third party sites, products or services.</p>
                    <h4 class="mt-5">Aggregated Statistics</h4>
                    <p>24-7Topup may collect statistics about the behavior of visitors to its website. 24-7Topup may
                        display this information publicly or provide it to others. However, 24-7Topup does not
                        disclose your personally-identifying information.</p>
                    <h4 class="mt-5">Cookies</h4>
                    <p>To enrich and perfect your online experience, 24-7Topup uses "Cookies", similar technologies
                        and services provided by others to display personalized content, appropriate advertising and
                        store your preferences on your computer.
                        A cookie is a string of information that a website stores on a visitor’s computer, and that
                        the visitor’s browser provides to the website each time the visitor returns. 24-7Topup uses
                        cookies to help 24-7Topup identify and track visitors, their usage of https://247topup.com,
                        and their website access preferences. 24-7Topup visitors who do not wish to have cookies
                        placed on their computers should set their browsers to refuse cookies before using
                        24-7Topup’s websites, with the drawback that certain features of 24-7Topup’s websites may
                        not function properly without the aid of cookies.
                        By continuing to navigate our website without changing your cookie settings, you hereby
                        acknowledge and agree to 24-7Topup's use of cookies.</p>
                    <h4 class="mt-5">Business Transfers</h4>
                    <p>If 24-7Topup, or substantially all of its assets, were acquired, or in the unlikely event
                        that 24-7Topup goes out of business or enters bankruptcy, user information would be one of
                        the assets that is transferred or acquired by a third party. You acknowledge that such
                        transfers may occur, and that any acquirer of 24-7Topup may continue to use your personal
                        information as set forth in this policy.</p>
                    <h4 class="mt-5">Privacy Policy Changes</h4>
                    <p>Although most changes are likely to be minor, 24-7Topup may change its Privacy Policy from
                        time to time, and in 24-7Topup’s sole discretion. 24-7Topup encourages visitors to
                        frequently check this page for any changes to its Privacy Policy. Your continued use of this
                        site after any change in this Privacy Policy will constitute your acceptance of such change.
                    </p>
                    <p>247Topup is an entity of BAAJA Technologies</p>
                </div>
            </div>

        </div>
    </div>
</section>