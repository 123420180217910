<div class="page-title-area item-bg-4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Loan Calculator</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Loan Calculator</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="loan-calculator ptb-100">
    <div class="container">
        <div class="calculator-form">
            <div class="text">
                <span>Loan Calculator</span>
                <h3>How much do you need?</h3>
            </div>

            <div class="form-group mb-3">
                <label>Loan Amount</label>
                <input type="text" class="form-control">
            </div>
            <div class="form-group mb-3">
                <label>Interest Rate (%)</label>
                <input type="text" class="form-control">
            </div>
            <div class="form-group mb-3">
                <label>Loan Term (Years)</label>
                <input type="text" class="form-control">
            </div>
            <div class="form-group mb-3">
                <label>Loan Start Date</label>
                <input type="text" class="form-control">
            </div>

            <div class="calculator-btn">
                <a routerLink="/loan-calculator" class="btn-one">View schedule</a>
                <a routerLink="/loan-calculator" class="btn-two">Download schedule</a>
            </div>
        </div>
    </div>
</section>

<section class="table-area pb-100">
    <div class="container">
        <div class="table-responsive">
            <table class="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th class="text-start">
                            Date
                        </th>
                        <th class="text-start">
                            Period
                        </th>
                        <th class="text-start">
                            Balance
                        </th>
                        <th class="text-start">
                            Pandle
                        </th>
                        <th class="text-start">
                            Interest
                        </th>
                        <th class="text-start">
                            Principle
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th class="text-nowrap" scope="row">07/07/2020</th>
                        <td>0</td>
                        <td>$500.00</td>
                        <td>$0.00</td>
                        <td>$0.00</td>
                        <td>$0.00</td>
                    </tr>
                    <tr>
                        <th class="text-nowrap" scope="row">08/07/2020</th>
                        <td>1</td>
                        <td>$459.28</td>
                        <td>$42.80</td>
                        <td> $2.08</td>
                        <td>$40.72</td>
                    </tr>
                    <tr>
                        <th class="text-nowrap" scope="row">09/07/2020</th>
                        <td>2</td>
                        <td>$418.39</td>
                        <td>$42.00</td>
                        <td>$1.91</td>
                        <td>$40.89</td>
                    </tr>
                    <tr>
                        <th class="text-nowrap" scope="row">10/07/2020</th>
                        <td>3</td>
                        <td>$377.33</td>
                        <td>$42.00</td>
                        <td>$1.74</td>
                        <td>$41.06</td>
                    </tr>
                    <tr>
                        <th class="text-nowrap" scope="row">11/07/2020</th>
                        <td>4</td>
                        <td>$336.10</td>
                        <td>$42.00</td>
                        <td>$1.57</td>
                        <td>$41.23</td>
                    </tr>
                    <tr>
                        <th class="text-nowrap" scope="row">12/07/2020</th>
                        <td>5</td>
                        <td>$294.67</td>
                        <td>$42.00</td>
                        <td>$1.40</td>
                        <td>$41.40</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</section>