import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/@core/services';
import { SelfcareLayoutComponent } from './components/selfcare-layout/selfcare-layout.component';
import { CartComponent } from './pages/cart/cart.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { PaymentSettingsComponent } from './pages/payment-settings/payment-settings.component';
import { RechargeStatusComponent } from './pages/recharge-status/recharge-status.component';
import { TopupComponent } from './pages/topup/topup.component';

const routes: Routes = [
  {
    path: "selfcare",
    component: SelfcareLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        redirectTo: "/selfcare/topup",
        pathMatch: "full"
      },
      {
        path: "topup",
        component: TopupComponent
      },
      {
        path: "payment-settings",
        component: PaymentSettingsComponent
      },
      {
        path: "cart",
        component: CartComponent
      },
      {
        path: "checkout",
        component: CheckoutComponent
      },
      {
        path: "recharge-status",
        component: RechargeStatusComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SelfcareRoutingModule { }
