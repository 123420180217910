<div class="page-title-area item-bg-8">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>FAQ</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Faq</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="faq-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Frequently ask question</h2>
            <b>Just find your answers below</b>
        </div>

        <div class="tab faq-list-tab mt-5">
            <!-- <ul class="tabs-list">
                <li><a href="#">General Question</a></li>
            </ul> -->

            <div class="tab_content">
                <div class="tabs_item">
                    <div class="faq-accordion">
                        <ul class="accordion">
                            <li class="accordion-item active">
                                <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-plus"></i>
                                    What is Top Up?
                                </a>
                                <p class="accordion-content">Just like sending money to relatives and friends, you can
                                    also send mobile/cellphone credit or top up to love ones at anytime. Some countries
                                    call it mobile recharge, phone credit, refill, airtime etc but we like to call it
                                    top up.
                                </p>
                            </li>
                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-plus"></i>
                                    How do I send a Top Up?
                                </a>
                                <div class="accordion-content">
                                    <p>Sending top up with us is easy. Please follow the steps below.</p>
                                    <ol>
                                        <li>Click on the "Send top up now button"</li>
                                        <li>Follow the prompt on the transaction page</li>
                                        <li>Select Country</li>
                                        <li>Select cellphone provider</li>
                                        <li>Enter Phone Number</li>
                                        <li>Select top up amount</li>
                                        <li>Click add to cart</li>
                                        <li>The recipient number is place in the shopping cart</li>
                                        <li>Repeat steps 3 to 7 to add another number to the transaction otherwise click
                                            on checkout</li>
                                        <li>
                                            Enter billing information, following data is required:
                                            <ol type="a">
                                                <li>Name</li>
                                                <li>Valid Email address</li>
                                                <li>Card details</li>
                                            </ol>
                                        </li>
                                        <li>Click check box to accept our terms and conditions</li>
                                        <li>Click on send top up now</li>
                                    </ol>
                                </div>
                            </li>
                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-plus"></i>
                                    How do i know if my Top up is successful?
                                </a>
                                <p class="accordion-content">If your top up is successful, you will receive a
                                    notification on the screen stating that your top up was successfully sent otherwise
                                    you will receive an error message when the transaction cannot be processed.
                                </p>
                            </li>
                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-plus"></i>
                                    How does the recipient know that they have received a top up?
                                </a>
                                <p class="accordion-content">The recipient receives an instant text message notification
                                    immediately a successful transaction is complete. They can also check the credit or
                                    top up balance by using the method provided by their cellphone provider.
                                </p>
                            </li>
                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-plus"></i>
                                    Do you provide receipt?
                                </a>
                                <p class="accordion-content">Yes, we provide an email receipt upon the completion of a
                                    successful transaction. This is the main reason why a valid email address is
                                    required.
                                </p>
                            </li>
                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-plus"></i>
                                    What if the recipient did not receive the top up?
                                </a>
                                <p class="accordion-content">Our system utilizes smart technology to transmit the credit
                                    to the phone number you provided. If the recipient did not receive the top up for a
                                    successful transaction, most likely you have made a typographical error and have
                                    inadvertently sent the top up to a different number.If you are 100% sure that you
                                    sent it to the correct number , then contact us so we can troubleshoot and resolve
                                    the issue.
                                </p>
                            </li>
                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-plus"></i>
                                    How long does it take for the recipient to receive the top up?
                                </a>
                                <p class="accordion-content">Our top up system is instant. Once you receive a successful
                                    top up confirmation on the screen, the recipient in parallel will receive the text
                                    message notification that top up has been added to their phone.
                                </p>
                            </li>
                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-plus"></i>
                                    What forms of payment do you accept?
                                </a>
                                <p class="accordion-content">We accept credit and debited card payments.
                                </p>
                            </li>
                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-plus"></i>
                                    Is your website secure?
                                </a>
                                <p class="accordion-content">Yes, as a rule of thumb make sure when you are doing online
                                    transactions the website address shows the small padlock next to it. This mean that
                                    communication send through the website is encrypted. Most importantly billing is
                                    processed by a very secured third party vendor and all data are sent through a
                                    secure channel. We value the safety of your data and will work hard to ensure that
                                    its always protected.
                                </p>
                            </li>
                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-plus"></i>
                                    Do you have terms and conditions?
                                </a>
                                <p class="accordion-content">Yes, using our service is subject to our terms and
                                    conditions. Once you successfully complete a transaction it implies you agreed to
                                    our terms and conditions.
                                </p>
                            </li>
                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-plus"></i>
                                    Do you offer Promotions?
                                </a>
                                <p class="accordion-content">
                                    Yes, we post promotions on our facebook page. Click the link below to follow us on
                                    facebook
                                    <a
                                        href="https://www.facebook.com/24-7Top-up-2353373691611495/">https://www.facebook.com/24-7Top-up-2353373691611495/</a>.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>


            </div>
        </div>

        <div class="faq-contact">
            <div class="section-title">
                <h2>Still, have questions?</h2>
            </div>

            <div class="faq-contact-form">
                <form id="contactForm">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                                <input type="text" name="name" class="form-control" placeholder="Your Name">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                                <input type="email" name="email" class="form-control" placeholder="Email">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group mb-3">
                                <textarea name="message" class="form-control" cols="30" rows="5"
                                    placeholder="Text Here"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <button type="submit" class="default-btn">Submit Now<span></span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>