import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';

import { ApiService } from './api.service';
import { JwtService } from './jwt.service';
import { User } from '../models';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { AuthInfo } from '../models/auth-info.model';


@Injectable()
export class UserService {
  private currentUserSubject = new BehaviorSubject<User>({} as User);
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  constructor(
    private apiService: ApiService,
    private jwtService: JwtService
  ) { }

  // Verify JWT in localstorage with server & load user's info.
  // This runs once on application startup.
  populate() {
    // If JWT detected, attempt to get & store user's info
    const token = this.jwtService.getToken();
    console.log("token: ", token, !!token)
    if (token) {
      this.apiService.get(`/profile`)
        .subscribe(
          data => {
            if (data) {
              this.currentUserSubject.next(data);
              this.isAuthenticatedSubject.next(true);
            }
            else this.purgeAuth();
          },
          err => this.purgeAuth()
        );
    } else {
      // Remove any potential remnants of previous auth states
      this.purgeAuth();
    }
  }

  setAuth(authInfo: AuthInfo) {
    // Save JWT sent from server in localstorage
    this.jwtService.saveToken(authInfo.token);
    // Set current user data into observable
    this.currentUserSubject.next(authInfo.user);
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
  }

  purgeAuth() {
    // Remove JWT from localstorage
    this.jwtService.destroyToken();
    // Set current user to an empty object
    this.currentUserSubject.next({} as User);
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
  }

  sendVerificationCode(email: string): Observable<User> {
    return this.apiService.post('/login', { email });
  }

  login(email: string, code: string): Observable<User> {
    return this.apiService.post('/verify-otp', {
      email,
      otp: code,
      device_name: "web",
    })
      .pipe(map(
        data => {
          this.setAuth(data);
          return data;
        }
      ));
  }

  register(params: {
    name: string,
    email: string,
    country: string,
    phone: string,
    address: string,
    city: string,
    state: string,
    postalCode: string
  }): Observable<User> {
    return this.apiService.post('/register', params);
  }

  getCurrentUser(): User {
    return this.currentUserSubject.value;
  }

  // Update the user on the server (email, pass, etc)
  update(user): Observable<User> {
    return this.apiService
      .put('/user', { user })
      .pipe(map(data => {
        // Update the currentUser observable
        this.currentUserSubject.next(data.user);
        return data.user;
      }));
  }

}