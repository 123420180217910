<div class="page-title-area item-bg-9">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Contact</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Contact</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="contact-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Let's discuss</span>
            <h2>Whatever question you have, please feel free to ask.</h2>
        </div>

        <div class="row">
            <div class="col-lg-8">
                <div class="contact-form">
                    <div class="title">
                        <h3>Write Us</h3>
                    </div>

                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-6 col-md-12">
                                <div class="form-group mb-3">
                                    <label>Name</label>
                                    <input type="text" name="name" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="form-group mb-3">
                                    <label>Email Address</label>
                                    <input type="email" name="email" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <label>Subject</label>
                                    <input type="text" name="subject" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <label>Message</label>
                                    <textarea name="message" class="form-control" cols="30" rows="5"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn">Send Message<span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="contact-side-box">
                    <div class="title">
                        <h3>Contact Topli</h3>
                    </div>

                    <div class="info-box">
                        <div class="icon">
                            <i class="flaticon-clock"></i>
                        </div>
                        <h4>Working hours</h4>
                        <ul class="list">
                            <li>Mon – Thu <span>8:00 AM - 5:00 PM</span></li>
                            <li>Fri – Sat <span>8:00 AM - 3:00 PM</span></li>
                            <li>Sun <span>CLOSED</span></li>
                        </ul>
                    </div>
                    <div class="info-box">
                        <div class="icon">
                            <i class="flaticon-pin"></i>
                        </div>
                        <h4>Address</h4>
                        <span>6890 Blvd, The Bronx, NY 1058 New York, USA</span>
                    </div>
                    <div class="info-box">
                        <div class="icon">
                            <i class="flaticon-phone-call"></i>
                        </div>
                        <h4>Phone</h4>
                        <span><a href="tel:1514312-6688">+1 (514) 312-5678</a></span>
                        <span><a href="tel:1514312-6688">+1 (514) 312-6688</a></span>
                    </div>
                    <div class="info-box">
                        <div class="icon">
                            <i class="flaticon-email"></i>
                        </div>
                        <h4>Email</h4>
                        <span><a href="mailto:hello@topli.com">hello@topli.com</a></span>
                        <span><a href="#">Skype: example</a></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="map">
    <div class="container-fluid">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.15830869428!2d-74.11976397304603!3d40.69766374874431!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1594738801148!5m2!1sen!2sbd"></iframe>
    </div>
</div>