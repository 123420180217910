import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeLayoutComponent } from './components/home-layout/home-layout.component';
import { ContactComponent } from './pages/contact/contact.component';
import { FaqComponent } from './pages/faqs/faq.component';
import { HomeComponent } from './pages/home/home.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { DeleteAccountComponent } from './pages/delete-account/delete-account.component';

const routes: Routes = [
  {
    path: "",
    component: HomeLayoutComponent,
    children: [
      {
        path: "",
        redirectTo: "/home",
        pathMatch: "full"
      },
      {
        path: "home",
        component: HomeComponent
      },
      {
        path: "faqs",
        component: FaqComponent
      },
      {
        path: "contact",
        component: ContactComponent
      },
      {
        path: 'privacy-policy', 
        component: PrivacyPolicyComponent
      },
      {
        path: 'terms-conditions', 
        component: TermsConditionsComponent
      },
      {
        path: "delete-account",
        component: DeleteAccountComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
