import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RechargeResult } from '../../models/recharge-result';

@Component({
  selector: 'app-recharge-status',
  templateUrl: './recharge-status.component.html',
  styleUrls: ['./recharge-status.component.scss']
})
export class RechargeStatusComponent implements OnInit {
  rechargeResults: RechargeResult[] = [];

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    if(!history.state.rechargeResults) {
      this.router.navigateByUrl("/selfcare/topup");
    }

    this.rechargeResults = history.state.rechargeResults;
  }

  get successCount() {
    return this.rechargeResults.filter(value => 
      value.status === "Complete").length;
  }

}
