<div class="navbar-area">
    <div class="main-responsive-nav">
        <div class="container">
            <div class="main-responsive-menu">
                <div class="logo">
                    <a routerLink="/"><img src="assets/img/logo.png" alt="image"></a>
                </div>
            </div>
        </div>
    </div>

    <div class="main-navbar">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="image"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a routerLink="/selfcare/topup" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Recharge</a>
                        </li>

                        <!-- <li class="nav-item">
                            <a routerLink="/selfcare/payment-settings" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Payment settings</a>
                        </li> -->

                        <li class="nav-item">
                            <a routerLink="/selfcare/cart" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Cart
                                <span class="badge bg-danger" *ngIf="(cartItemsCount | async)">{{ cartItemsCount | async }}</span>
                            </a>
                        </li>
                    </ul>

                    <div class="others-options d-flex align-items-center">
                        <div class="option-item">
                            <a routerLink="" class="default-btn" (click)="signOut()">Sign out<span></span></a>
                        </div>
                    </div>
                    
                </div>
            </nav>
        </div>
    </div>
</div>