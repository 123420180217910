<div class="page-title-area item-bg-5">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Terms & Conditions</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Terms & Conditions</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="terms-of-condition-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="terms-of-condition-content">
                    <div>
                        <h4>General</h4>
                        <p>By accessing this website, we assume you accept these terms and conditions in full. Do not
                            continue to use 24-7Topup's website if you do not accept all of the terms and conditions
                            stated on this page.The following terminology applies to these Terms and Conditions, Privacy
                            Statement and Disclaimer Notice and any or all Agreements: “Client”, “You” and “Your” refers
                            to you, the person accessing this website and accepting the Company’s terms and conditions.
                            “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our Company. “Party”, “Parties”,
                            or “Us”, refers to both the Client and ourselves, or either the Client or ourselves. All
                            terms refer to the offer, acceptance and consideration of payment necessary to undertake the
                            process of our assistance to the Client in the most appropriate manner, whether by formal
                            meetings of a fixed duration, or any other means, for the express purpose of meeting the
                            Client’s needs in respect of provision of the Company’s stated services/products, in
                            accordance with and subject to, prevailing law. Any use of the above terminology or other
                            words in the singular, plural, capitalization and/or he/she or they, are taken as
                            interchangeable and therefore as referring to same.</p>
                    </div>
                    <div class="mt-4">
                        <h4>Cookies</h4>
                        <p>We employ the use of cookies. By using 24-7Topup's website, you consent to the use of cookies
                            in accordance with 24-7Topup’s privacy policy. Most of the modern day interactive web sites
                            use cookies to enable us to retrieve user details for each visit. Cookies are used in some
                            areas of our site to enable the functionality of this area and ease of use for those people
                            visiting. Some of our affiliate / advertising partners may also use cookies.</p>
                    </div>
                    <div class="mt-4">
                        <h4>License</h4>
                        <p>Unless otherwise stated, 24-7Topup and/or its licensors own the intellectual property rights
                            for all material on 24-7Topup. All intellectual property rights are reserved. You may view
                            and/or print pages from https://247topup.com for your own personal use subject to
                            restrictions set in these terms and conditions.</p>
                        <div>
                            You must not:
                            <ul>
                                <li>Republish material from https://247topup.com</li>
                                <li>Sell, rent or sub-license material from https://247topup.com</li>
                                <li>Reproduce, duplicate or copy material from https://247topup.com</li>
                                <li>Redistribute content from 24-7Topup (unless content is specifically made for
                                    redistribution).</li>
                            </ul>
                        </div>
                    </div>
                    <div class="mt-4">
                        <h4>Hyperlinking to our Content</h4>
                        <div>
                            <p>The following organizations may link to our Web site without prior written approval:</p>
                            <ul>
                                <li>Government agencies;</li>
                                <li>Search engines;</li>
                                <li>News organizations;</li>
                            </ul>
                            <p>Online directory distributors when they list us in the directory may link to our Web site
                                in the same manner as they hyperlink to the Web sites of other listed businesses; and
                            </p>
                            <p>System wide Accredited Businesses except soliciting non-profit organizations, charity
                                shopping malls, and charity fundraising groups which may not hyperlink to our Web site.
                            </p>
                            <p>These organizations may link to our home page, to publications or to other Web site
                                information so long as the link: (a) is not in any way misleading; (b) does not falsely
                                imply sponsorship, endorsement or approval of the linking party and its products or
                                services; and (c) fits within the context of the linking
                                party's site.</p>
                            <p>We may consider and approve in our sole discretion other link requests from the following
                                types of organizations:</p>
                            <ul>
                                <li>commonly-known consumer and/or business information sources such as Chambers of
                                    Commerce, American</li>
                                <li>Automobile Association, AARP and Consumers Union;</li>
                                <li>dot.com community sites;</li>
                                <li>associations or other groups representing charities, including charity giving sites,
                                </li>
                                <li>online directory distributors;</li>
                                <li>internet portals;</li>
                                <li>accounting, law and consulting firms whose primary clients are businesses; and</li>
                                <li>educational institutions and trade associations.</li>
                            </ul>
                            <p>We will approve link requests from these organizations if we determine that:</p>
                            <ol type="a">
                                <li>the link would not reflect unfavorably on us or our accredited businesses (for
                                    example, trade associations or other organizations representing inherently suspect
                                    types of business, such as work-at-home opportunities, shall not be allowed to
                                    link);</li>
                                <li>the organization does not have an unsatisfactory record with us; </li>
                                <li>the benefit to us from the visibility associated with the hyperlink outweighs the
                                    absence of ; and</li>
                                <li>where the link is in the context of general resource information or is otherwise
                                    consistent with editorial content in a newsletter or similar product furthering the
                                    mission of the organization.</li>
                            </ol>

                            <p>These organizations may link to our home page, to publications or to other Web site
                                information so long as the link:</p>
                            <ul>
                                <li>is not in any way misleading;</li>
                                <li>does not falsely imply sponsorship, endorsement or approval of the linking party and
                                    it products or services; and</li>
                                <li>fits within the context of the linking party's site.</li>
                            </ul>

                            <p>If you are among the organizations listed in paragraph 2 above and are interested in
                                linking to our website, you must notify us by sending an e-mail to info@247topup.com.
                                Please include your name, your organization name, contact information (such as a phone
                                number and/or e-mail address) as well as the URL of your site, a list of any URLs from
                                which you intend to link to our Web site, and a list of the URL(s) on our site to which
                                you would like to link. Allow 2-3 weeks for a response.</p>

                            <p>Approved organizations may hyperlink to our Web site as follows:</p>
                            <ul>
                                <li>By use of our corporate name; or</li>
                                <li>By use of the uniform resource locator (Web address) being linked to; or</li>
                                <li>By use of any other description of our Web site or material being linked to that
                                    makes sense within the context and format of content on the linking party's site.
                                </li>
                            </ul>
                            <p>No use of 24-7Topup’s logo or other artwork will be allowed for linking absent a
                                trademark license agreement.</p>
                        </div>
                    </div>
                    <div class="mt-4">
                        <h4>Iframes</h4>
                        <p>Without prior approval and express written permission, you may not create frames around our
                            Web pages or use other techniques that alter in any way the visual presentation or
                            appearance of our Web site.</p>
                    </div>
                    <div class="mt-4">
                        <h4>Reservation of Rights</h4>
                        <p>We reserve the right at any time and in its sole discretion to request that you remove all
                            links or any particular link to our Web site. You agree to immediately remove all links to
                            our Web site upon such request. We also reserve the right to amend these terms and
                            conditions and its linking policy at any time. By continuing to link to our Web site, you
                            agree to be bound to and abide by these linking terms and conditions.</p>
                    </div>
                    <div class="mt-4">
                        <h4>Removal of links from our website</h4>
                        <p>If you find any link on our Web site or any linked web site objectionable for any reason, you
                            may contact us about this. We will consider requests to remove links but will have no
                            obligation to do so or to respond directly to you. Whilst we endeavor to ensure that the
                            information on this website is correct, we do not warrant its completeness or accuracy; nor
                            do we commit to ensuring that the website remains available or that the material on the
                            website is kept up to date.</p>
                    </div>
                    <div class="mt-4">
                        <h4>Content Liability</h4>
                        <p>We shall have no responsibility or liability for any content appearing on your Web site. You
                            agree to indemnify and defend us against all claims arising out of or based upon your
                            Website. No link(s) may appear on any page on your Web site or within any context containing
                            content or materials that may be interpreted as libelous, obscene or criminal, or which
                            infringes, otherwise violates, or advocates the infringement or other violation of, any
                            third party rights.</p>
                    </div>
                    <div class="mt-4">
                        <h4>Transactions</h4>
                        <ul>
                            <li>24-7 TopUp service shall be provided for mobile phone operators listed on the Website
                                and are subject to change and availability.</li>
                            <li>Client is required to input the mobile phone number to which any Top-Up is to be
                                credited into the appropriate space on the Website.</li>
                            <li>Client is responsible to ensure that the correct phone number is entered in the system
                                prior to processing order.</li>
                            <li>Client is required to select the amount of Top-Up that you wish to credit that mobile
                                phone number.</li>
                            <li>Client is required to review order summary and make necessary changes if needed before
                                submitting order for processing.</li>
                            <li>Client agrees that when an order is submitted, it is an explicit authorization for
                                24-7Topup to send mobile credit to the phone number entered in the order through the
                                mobile operator.</li>
                            <li>Client understands that once a top up is sent it cannot be refunded or removed from the
                                mobile number because the recipient of the top up can use it immediately if they so
                                choose.</li>
                            <li>Client agrees to pay a minimum processing fee for all transactions.</li>
                            <li>Client understands and agree that by inputting credit or debit card information, on our
                                website, it is an explicit authorization for 24-7Topup to charge their card the sum
                                total of the order.</li>
                            <li>Client understands that recipient mobile operator may deduct taxes and other charges as applicable to the top up amount received.</li>
                        </ul>
                    </div>
                    <div class="mt-4">
                        <h4>Disclaimer</h4>
                        <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties
                            and conditions relating to our website and the use of this website (including, without
                            limitation, any warranties implied by law in respect of satisfactory quality, fitness for
                            purpose and/or the use of reasonable care and skill). Nothing in this disclaimer will:</p>
                        <ul>
                            <li>limit or exclude our or your liability for death or personal injury resulting from
                                negligence;</li>
                            <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                            <li>limit any of our or your liabilities in any way that is not permitted under applicable
                                law; or exclude any of our or your liabilities that may not be excluded under applicable
                                law.</li>
                        </ul>

                        <p>The limitations and exclusions of liability set out in this Section and elsewhere in this
                            disclaimer:</p>
                        <ol type="a">
                            <li>are subject to the preceding paragraph; and </li>
                            <li>govern all liabilities arising under the disclaimer or in relation to the subject matter
                                of this disclaimer, including liabilities arising in contract, in tort (including
                                negligence) and for breach of statutory duty.</li>
                        </ol>

                        <p>To the extent that the website and the information and services on the website are provided
                            free of charge, we will not be liable for any loss or damage of any nature.</p>

                        <p>247 Topup is an entity of BAAJA Technologies</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>