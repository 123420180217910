<div class="page-title-area item-bg-5">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Gallery</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Gallery</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="gallery-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="gallery-item">
                    <div class="image">
                        <a routerLink="/gallery"><img src="assets/img/gallery/image1.jpg" alt="image"></a>
                    </div>

                    <div class="content">
                        <h3><a routerLink="/gallery">Financial Planning</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="gallery-item">
                    <div class="image">
                        <a routerLink="/gallery"><img src="assets/img/gallery/image2.jpg" alt="image"></a>
                    </div>

                    <div class="content">
                        <h3><a routerLink="/gallery">Startup Funding</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="gallery-item">
                    <div class="image">
                        <a routerLink="/gallery"><img src="assets/img/gallery/image3.jpg" alt="image"></a>
                    </div>

                    <div class="content">
                        <h3><a routerLink="/gallery">Fund Management</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="gallery-item">
                    <div class="image">
                        <a routerLink="/gallery"><img src="assets/img/gallery/image4.jpg" alt="image"></a>
                    </div>

                    <div class="content">
                        <h3><a routerLink="/gallery">Investment Shares</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="gallery-item">
                    <div class="image">
                        <a routerLink="/gallery"><img src="assets/img/gallery/image5.jpg" alt="image"></a>
                    </div>

                    <div class="content">
                        <h3><a routerLink="/gallery">Strategy</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="gallery-item">
                    <div class="image">
                        <a routerLink="/gallery"><img src="assets/img/gallery/image6.jpg" alt="image"></a>
                    </div>

                    <div class="content">
                        <h3><a routerLink="/gallery">Finance</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="gallery-item">
                    <div class="image">
                        <a routerLink="/gallery"><img src="assets/img/gallery/image7.jpg" alt="image"></a>
                    </div>

                    <div class="content">
                        <h3><a routerLink="/gallery">Investments Loan</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="gallery-item">
                    <div class="image">
                        <a routerLink="/gallery"><img src="assets/img/gallery/image8.jpg" alt="image"></a>
                    </div>

                    <div class="content">
                        <h3><a routerLink="/gallery">Business Purpose</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="gallery-item">
                    <div class="image">
                        <a routerLink="/gallery"><img src="assets/img/gallery/image9.jpg" alt="image"></a>
                    </div>

                    <div class="content">
                        <h3><a routerLink="/gallery">Commercial Loan</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>