<div class="page-title-area item-bg-7">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="services-area bg-color pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Knowledge of the market</span>
            <h2>Only the best professional services</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="services-item">
                    <div class="icon">
                        <i class="flaticon-agriculture"></i>
                    </div>
                    <h3>Agricultural loan</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ncid dunt ut labore et dolore magna aliqua.</p>
                    <a routerLink="/services-details" class="learn-btn">Learn More</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-item">
                    <div class="icon">
                        <i class="flaticon-loan-1"></i>
                    </div>
                    <h3>Business loan</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ncid dunt ut labore et dolore magna aliqua.</p>
                    <a routerLink="/services-details" class="learn-btn">Learn More</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-item">
                    <div class="icon">
                        <i class="flaticon-loan-2"></i>
                    </div>
                    <h3>House loan</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ncid dunt ut labore et dolore magna aliqua.</p>
                    <a routerLink="/services-details" class="learn-btn">Learn More</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-item">
                    <div class="icon">
                        <i class="flaticon-personal"></i>
                    </div>
                    <h3>Personal loan</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ncid dunt ut labore et dolore magna aliqua.</p>
                    <a routerLink="/services-details" class="learn-btn">Learn More</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-item">
                    <div class="icon">
                        <i class="flaticon-scholarship"></i>
                    </div>
                    <h3>Education loan</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ncid dunt ut labore et dolore magna aliqua.</p>
                    <a routerLink="/services-details" class="learn-btn">Learn More</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-item">
                    <div class="icon">
                        <i class="flaticon-loan-3"></i>
                    </div>
                    <h3>Payday loan</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ncid dunt ut labore et dolore magna aliqua.</p>
                    <a routerLink="/services-details" class="learn-btn">Learn More</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-item">
                    <div class="icon">
                        <i class="flaticon-car"></i>
                    </div>
                    <h3>Vehicle loan</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ncid dunt ut labore et dolore magna aliqua.</p>
                    <a routerLink="/services-details" class="learn-btn">Learn More</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-item">
                    <div class="icon">
                        <i class="flaticon-loan-4"></i>
                    </div>
                    <h3>Medical loan</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ncid dunt ut labore et dolore magna aliqua.</p>
                    <a routerLink="/services-details" class="learn-btn">Learn More</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-item">
                    <div class="icon">
                        <i class="flaticon-rocket"></i>
                    </div>
                    <h3>StartUp loan</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ncid dunt ut labore et dolore magna aliqua.</p>
                    <a routerLink="/services-details" class="learn-btn">Learn More</a>
                </div>
            </div>
        </div>
    </div>
</section>