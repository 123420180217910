import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/@core/services';
import { EstimatedPrice } from '../models/estimated-price';

@Injectable({
  providedIn: 'root'
})
export class EstimatedPriceService {

  constructor(
    private apiService: ApiService
  ) {
    // 
  }

  retrieveAll(skuCode: string): Observable<EstimatedPrice[]> {
    return this.apiService.get('/estimatedPrices', new HttpParams({
      fromObject: { skuCode }
    }));
  }
}
