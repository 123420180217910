<section class="ptb-100">
    <div class="container">

        <div id="recharge-details">
            <div>
                <h2 class="fs-1">Online recharge</h2>
                <h6 class="fw-normal text-muted mt-2">Fill in the information below to search plans.</h6>
            </div>

            <form class="apply-form mt-4 pt-3" novalidate="novalidate" [formGroup]="form" (ngSubmit)="searchPlans()">
                <div class="row gy-4">
                    <div class="col-12 col-md-6 col-lg-4 form-group">
                        <ng-select2 formControlName="country" placeholder="Country" [data]="countriesFieldData"
                            [options]="{templateResult: templateResult, templateSelection: templateResult}"
                            (valueChanged)="handleCountryUpdate($event)"
                            [ngClass]="{'is-invalid': (country.touched || isFormSubmitAttempted) && country.invalid}">
                        </ng-select2>
                        <div *ngIf="country.errors?.required" class="invalid-feedback">
                            This field is required.
                        </div>
                        <div *ngIf="country.errors?.inavlidCountry" class="invalid-feedback">
                            Invalid country chosen.
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 form-group">
                        <ng-select2 formControlName="provider" placeholder="Provider" [data]="providersFieldData"
                            [options]="{templateResult: templateResult, templateSelection: templateResult}"
                            (valueChanged)="handleProviderUpdate($event)"
                            [ngClass]="{'is-invalid': (provider.touched || isFormSubmitAttempted) && provider.invalid}">
                        </ng-select2>
                        <div *ngIf="provider.errors?.required" class="invalid-feedback">
                            This field is required.
                        </div>
                        <div *ngIf="provider.errors?.inavlidProvider" class="invalid-feedback">
                            Invalid provider chosen.
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 form-group">
                        <input type="tel" formControlName="phoneNumber" class="form-control"
                            placeholder="Phone number (without country code)"
                            [ngClass]="{'is-invalid': (phoneNumber.touched || isFormSubmitAttempted) && phoneNumber.invalid}"
                            [minlength]="phoneNumberMinLength" [maxlength]="phoneNumberMaxLength">
                        <div *ngIf="phoneNumber.errors?.required" class="invalid-feedback">
                            This field is required.
                        </div>
                        <div *ngIf="phoneNumber.errors?.invalidPhoneNumber" class="invalid-feedback">
                            {{ phoneNumber.errors.invalidPhoneNumber.message }}
                        </div>
                    </div>

                    <div class="col-12 mt-4 pt-1">
                        <button class="btn default-btn btn-lg">Search plans</button>
                    </div>
                </div>

            </form>
        </div> <!-- /#recharge-details -->

        <div *ngIf="plans.length" id="plans" class="mt-5 pt-4">
            <h2 class="fs-4 mb-4">Available plans ({{ shouldShowCustomPlans ? customPlans.length : plans.length }})</h2>

            <div class="plans row gy-4 gx-4">

                <ng-container *ngIf="!shouldShowCustomPlans; else customPlansElement">
                    <ng-container *ngIf="plans.length; else emptyPlanElement">
                        <div *ngFor="let plan of plans" class="col-12 col-lg-6">
                            <div class="plan">
                                <div class="amount">{{ plan.Maximum.ReceiveValue }} {{ plan.Maximum.ReceiveCurrencyIso
                                    }}
                                </div>
                                <div class="info">
                                    <p class="title">Price</p>
                                    <h6 class="value">{{ plan.Maximum.SendValue }} {{ plan.Maximum.SendCurrencyIso }}
                                    </h6>
                                </div>
                                <div class="button-wrapper">
                                    <button class="default-btn" (click)="addPlanToCart(plan)">
                                        <svg class="icon" aria-hidden="true" focusable="false" data-prefix="far"
                                            data-icon="cart-plus" role="img" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 576 512">
                                            <path fill="currentColor"
                                                d="M551.991 64H144.28l-8.726-44.608C133.35 8.128 123.478 0 112 0H12C5.373 0 0 5.373 0 12v24c0 6.627 5.373 12 12 12h80.24l69.594 355.701C150.796 415.201 144 430.802 144 448c0 35.346 28.654 64 64 64s64-28.654 64-64a63.681 63.681 0 0 0-8.583-32h145.167a63.681 63.681 0 0 0-8.583 32c0 35.346 28.654 64 64 64s64-28.654 64-64c0-18.136-7.556-34.496-19.676-46.142l1.035-4.757c3.254-14.96-8.142-29.101-23.452-29.101H203.76l-9.39-48h312.405c11.29 0 21.054-7.869 23.452-18.902l45.216-208C578.695 78.139 567.299 64 551.991 64zM464 424c13.234 0 24 10.766 24 24s-10.766 24-24 24-24-10.766-24-24 10.766-24 24-24zm-256 0c13.234 0 24 10.766 24 24s-10.766 24-24 24-24-10.766-24-24 10.766-24 24-24zm279.438-152H184.98l-31.31-160h368.548l-34.78 160zM272 200v-16c0-6.627 5.373-12 12-12h32v-32c0-6.627 5.373-12 12-12h16c6.627 0 12 5.373 12 12v32h32c6.627 0 12 5.373 12 12v16c0 6.627-5.373 12-12 12h-32v32c0 6.627-5.373 12-12 12h-16c-6.627 0-12-5.373-12-12v-32h-32c-6.627 0-12-5.373-12-12z"
                                                class=""></path>
                                        </svg>
                                        <div class="label">Add to cart</div>
                                    </button>
                                </div>
                            </div> <!-- /.plan -->
                        </div> <!-- /.col -->
                    </ng-container>
                </ng-container>

                <ng-template #customPlansElement>
                    <ng-container *ngIf="customPlans.length; else emptyPlanElement">
                        <div *ngFor="let customPlan of customPlans" class="col-12 col-lg-6">
                            <div class="plan">
                                <div class="amount">{{ customPlan.receiveValue }} {{
                                    plans[0].Maximum.ReceiveCurrencyIso }}
                                </div>
                                <div class="info">
                                    <p class="title">Price</p>
                                    <h6 class="value">{{ customPlan.sendValue }} {{ plans[0].Maximum.SendCurrencyIso }}
                                    </h6>
                                </div>
                                <div class="button-wrapper">
                                    <button class="default-btn" (click)="addCustomPlanToCart(customPlan)">
                                        <svg class="icon" aria-hidden="true" focusable="false" data-prefix="far"
                                            data-icon="cart-plus" role="img" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 576 512" class="svg-inline--fa fa-cart-plus fa-w-18 fa-2x">
                                            <path fill="currentColor"
                                                d="M551.991 64H144.28l-8.726-44.608C133.35 8.128 123.478 0 112 0H12C5.373 0 0 5.373 0 12v24c0 6.627 5.373 12 12 12h80.24l69.594 355.701C150.796 415.201 144 430.802 144 448c0 35.346 28.654 64 64 64s64-28.654 64-64a63.681 63.681 0 0 0-8.583-32h145.167a63.681 63.681 0 0 0-8.583 32c0 35.346 28.654 64 64 64s64-28.654 64-64c0-18.136-7.556-34.496-19.676-46.142l1.035-4.757c3.254-14.96-8.142-29.101-23.452-29.101H203.76l-9.39-48h312.405c11.29 0 21.054-7.869 23.452-18.902l45.216-208C578.695 78.139 567.299 64 551.991 64zM464 424c13.234 0 24 10.766 24 24s-10.766 24-24 24-24-10.766-24-24 10.766-24 24-24zm-256 0c13.234 0 24 10.766 24 24s-10.766 24-24 24-24-10.766-24-24 10.766-24 24-24zm279.438-152H184.98l-31.31-160h368.548l-34.78 160zM272 200v-16c0-6.627 5.373-12 12-12h32v-32c0-6.627 5.373-12 12-12h16c6.627 0 12 5.373 12 12v32h32c6.627 0 12 5.373 12 12v16c0 6.627-5.373 12-12 12h-32v32c0 6.627-5.373 12-12 12h-16c-6.627 0-12-5.373-12-12v-32h-32c-6.627 0-12-5.373-12-12z"
                                                class=""></path>
                                        </svg>
                                        <span class="label">Add to cart</span>
                                    </button>
                                </div>
                            </div> <!-- /.plan -->
                        </div> <!-- /.col -->
                    </ng-container>
                </ng-template>

                <ng-template #emptyPlanElement>
                    <div class="col-12 col-lg-6">
                        <div class="list-error">
                            <img src="assets/img/error.png" alt="">
                            <div class="info">
                                <h5 class="primary-message">Nothing in here!</h5>
                                <p class="secondary-message text-muted">No plans have been found for this provider.
                                    Please check back
                                    later.</p>
                            </div>
                        </div>
                    </div>
                </ng-template>

            </div>
        </div> <!-- /#plans -->

    </div> <!-- /.container -->
</section>

<ng-container [ngSwitch]="loadingState">
    <app-loader *ngSwitchCase="loadingStates.retrievingCountries" primaryMessage="Fetching countries"
        secondaryMessage="Please wait..."></app-loader>
    <app-loader *ngSwitchCase="loadingStates.retrievingProviders" primaryMessage="Fetching providers"
        secondaryMessage="Please wait..."></app-loader>
    <app-loader *ngSwitchCase="loadingStates.retrievingPlans" primaryMessage="Fetching plans"
        secondaryMessage="Please wait..."></app-loader>
    <app-loader *ngSwitchCase="loadingStates.validatingDetails" primaryMessage="Validating information"
        secondaryMessage="Please wait..."></app-loader>
    <app-loader *ngSwitchCase="loadingStates.addingToCart" primaryMessage="Adding to cart"
        secondaryMessage="Please wait..."></app-loader>
    <app-loader *ngSwitchCase="loadingStates.other"></app-loader>
</ng-container>