import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
import { UserService } from 'src/app/@core/services';
import { environment } from 'src/environments/environment';

declare const jQuery: any;

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  error?: string;
  isSubmitting = false;
  isFormSubmitAttempted: boolean = false;
  countryResults: { [key: string]: any }[] = [];

  countrySelect2Options: Options;

  form: FormGroup;


  constructor(
    private router: Router,
    private userService: UserService,
    private fb: FormBuilder
  ) {
    this.countrySelect2Options = {
      templateResult: this.templateResult,
      templateSelection: this.templateResult,
      ajax: {
        url: `${environment.apiEndpoint}/countries-autocomplete`,
        dataType: 'json',
        processResults: data => {
          this.countryResults = data;
          const resultData = data.map(value => {
            return {
              id: value.code,
              text: `${value.name} (+${value.dial_code})`,
              additional: {
                image: value.flag
              }
            };
          });
          return {
            results: resultData
          };
        }
      },
    };

    this.form = this.fb.group({
      'name': ['', [Validators.required]],
      'email': ['', [Validators.required, Validators.email]],
      'address': ['', Validators.required],
      'city': ['', Validators.required],
      'state': ['', Validators.required],
      'postalCode': ['', [Validators.required]],
      'country': ['', [Validators.required]],
      'phone': ['', [Validators.required, this.phoneNumberValidator()]],
    });
  }

  ngOnInit(): void {
    // 
  }

  get name() {
    return this.form.get('name');
  }

  get email() {
    return this.form.get('email');
  }

  get address() {
    return this.form.get('address');
  }

  get city() {
    return this.form.get('city');
  }

  get state() {
    return this.form.get('state');
  }

  get postalCode() {
    return this.form.get('postalCode');
  }

  get country() {
    return this.form.get('country');
  }

  get phone() {
    return this.form.get('phone');
  }

  templateResult(state: Select2OptionData) {
    if (!state.id) {
      return state.text;
    }

    if (!state?.additional?.image) {
      return state.text;
    } else {
      const option = jQuery(
        '<span><img class="select2-option-image" src="' + state.additional.image + '" /> ' + state.text + '</span>'
      );
      return option;
    }
  }

  handleCountryUpdate(value: string) {
    this.form.updateValueAndValidity();
  }

  phoneNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) return null;

      if (/^[0-9]+$/g.test(control.value) === false) {
        return {
          invalidPhoneNumber: {
            value: control.value,
            message: "Invalid phone number."
          }
        };
      }

      if (this.country.value) {
        const country = this.countryResults.find((value) =>
          value.code === this.country.value);

        const phoneNumber = `${country.dial_code}${control.value}`;

        if (!(phoneNumber.length >= country.min_phone_length &&
          phoneNumber.length <= country.max_phone_length)) {
          return {
            invalidPhoneNumber: {
              value: control.value,
              message: country.min_phone_length == country.max_phone_length
                ? `Phone number must have ${country.min_phone_length - country.dial_code.length} digits.`
                : `Phone number must have between ${country.min_phone_length - country.dial_code.length} and ${country.max_phone_length - country.dial_code.length} digits.`
            }
          };
        }
      }

      return null;
    };
  }

  async submit() {
    this.isFormSubmitAttempted = true;

    // Trim input values
    this.name.setValue(this.name.value.trim());
    this.email.setValue(this.email.value.trim());
    this.address.setValue(this.address.value.trim());
    this.city.setValue(this.city.value.trim());
    this.state.setValue(this.state.value.trim());
    this.postalCode.setValue(this.postalCode.value.trim());
    this.phone.setValue(this.phone.value.trim());

    if (this.form.invalid) return;

    this.isSubmitting = true;
    this.error = undefined;

    const country = this.countryResults
      .find((value) => value.code === this.country.value);

    try {
      await this.userService.register({
        name: this.form.value.name,
        email: this.form.value.email,
        address: this.form.value.address,
        city: this.form.value.city,
        state: this.form.value.state,
        postalCode: this.form.value.postalCode,
        country: this.form.value.country,
        phone: `+${country.dial_code}${this.form.value.phone}`,
      }).toPromise();
      this.router.navigateByUrl(`/auth/verify/${this.email.value}`);
    } catch (err) {
      console.log("Error: ", err);
      this.error = err?.message || "Something went wrong.";
      this.isSubmitting = false;
    }
  }

}
