import { NgModule } from '@angular/core';

import { HomeRoutingModule } from './home-routing.module';
import { SharedModule } from 'src/app/@shared';
import { HomeComponent } from './pages/home/home.component';
import { FaqComponent } from './pages/faqs/faq.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HomeLayoutComponent } from './components/home-layout/home-layout.component';
import { PrivacyPolicyComponent } from 'src/app/components/pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { DeleteAccountComponent } from './pages/delete-account/delete-account.component';


@NgModule({
  declarations: [
    HomeComponent,
    FaqComponent,
    ContactComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    DeleteAccountComponent,
    HomeLayoutComponent,
  ],
  imports: [
    SharedModule,
    HomeRoutingModule
  ]
})
export class HomeModule { }
