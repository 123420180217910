<!-- <div class="page-title-area item-bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Sign In</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Sign In</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="sign-in-area ptb-100">
    <div class="container">
        <div class="sign-in-form">
            <div class="sign-in-title">
                <h3>Welcome Back!</h3>
                <p>Please Sign In to your account.</p>
            </div>

            <form novalidate="novalidate" [formGroup]="authForm" (ngSubmit)="submitForm()">

                <!-- begin::Alert error-->
                <ng-container *ngIf="error">
                    <div class="mb-10 alert alert-danger alert-dismissible fade show" role="alert">
                        <div class="alert-text">{{ error }}</div>
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                </ng-container>
                <!-- end::Alert error-->

                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group has-danger mb-3">
                            <input type="email" class="form-control" placeholder="Email address" formControlName="email"
                                [ngClass]="{'is-invalid': (email.touched || isFormSubmitAttempted) && email.invalid}">
                            <div *ngIf="email.errors?.required" class="invalid-feedback">
                                This field is required.
                            </div>
                            <div *ngIf="email.errors?.email" class="invalid-feedback">
                                Invalid email address entered.
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="send-btn">
                            <button *ngIf="!isSubmitting" type="submit" class="default-btn w-100">
                                Sign in
                            </button>
                            <button *ngIf="isSubmitting" class="default-btn w-100" disabled>
                                Signing in
                                <div class="spinner-border spinner-border-sm" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </button>
                        </div>
                        <br>
                        <span>Don't have account? <a routerLink="/auth/sign-up" href="/auth/sign-up">Signup!</a></span>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>