<section class="ptb-100">
    <div class="container">

        <ng-container *ngIf="loadingState !== loadingStates.retrievingItems">
            <div class="row gx-5">

                <div class="col-12 col-lg-7">
                    <div id="plans">
                        <h2 class="fs-4 mb-4">Choose a payment method</h2>

                        <div class="payment-methods row gy-4 gx-4">

                            <div *ngFor="let method of paymentMethods" class="col-12">
                                <label class="payment-method">
                                    <div class="input-wrapper">
                                        <input type="radio" name="paymentMethod" [value]="method.id"
                                            [(ngModel)]="activePaymentMethod"
                                            (ngModelChange)="handleActivePaymentMethodUpdate()">
                                    </div>
                                    <div class="info">
                                        <h6 class="fw-normal">{{ method.card.brand | titlecase }} •••• {{
                                            method.card.last4 }}</h6>
                                        <p class="m-0 text-muted">Expires on {{ method.card.exp_month}}, {{
                                            method.card.exp_year }}</p>
                                        <div class="mt-1" *ngIf="activePaymentMethod==method.id">
                                            <div class="col-12 mt-1">
                                                <button type="submit" (click)="payWithCard(false)"
                                                    class="default-btn mt-3">Pay
                                                    now</button>
                                            </div>
                                        </div>
                                    </div>
                                </label> <!-- /.plan -->
                            </div> <!-- /.col -->

                            <div class="col-12">
                                <label class="payment-method">
                                    <div class="input-wrapper">
                                        <input type="radio" name="paymentMethod" value="newCard"
                                            [(ngModel)]="activePaymentMethod">
                                    </div>
                                    <div class="info">
                                        <h6 class="fw-normal">Credit / Debit card</h6>
                                        <p class="m-0 text-muted">Pay with a new card</p>
                                        <div class="mt-4" *ngIf="activePaymentMethod=='newCard'">
                                            <div class="row gy-3 new-card-form" [formGroup]="newCardForm">
                                                <div class="col-12">
                                                    <input type="text" class="form-control" placeholder="Name"
                                                        formControlName="name"
                                                        [ngClass]="{'is-invalid': name.touched && name.invalid}">
                                                    <div *ngIf="name.errors?.required" class="invalid-feedback">
                                                        This field is required.
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <label class="w-100">
                                                        <ngx-stripe-card [options]="cardOptions"
                                                            (change)="onCardInputChange($event)"
                                                            [elementsOptions]="elementsOptions"></ngx-stripe-card>
                                                    </label>
                                                </div>
                                                <div class="col-12">
                                                    <input type="text" class="form-control" placeholder="Address"
                                                        formControlName="address"
                                                        [ngClass]="{'is-invalid': address.touched && address.invalid}">
                                                    <div *ngIf="address.errors?.required" class="invalid-feedback">
                                                        This field is required.
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-6">
                                                    <input type="text" class="form-control" placeholder="City"
                                                        formControlName="city"
                                                        [ngClass]="{'is-invalid': city.touched && city.invalid}">
                                                    <div *ngIf="city.errors?.required" class="invalid-feedback">
                                                        This field is required.
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-6">
                                                    <input type="text" class="form-control" placeholder="State"
                                                        formControlName="state"
                                                        [ngClass]="{'is-invalid': state.touched && state.invalid}">
                                                    <div *ngIf="state.errors?.required" class="invalid-feedback">
                                                        This field is required.
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-6">
                                                    <input type="text" class="form-control"
                                                        placeholder="Zip/postal code" formControlName="postalCode"
                                                        [ngClass]="{'is-invalid': postalCode.touched && postalCode.invalid}">
                                                    <div *ngIf="postalCode.errors?.required" class="invalid-feedback">
                                                        This field is required.
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-6">
                                                    <ng-select2 formControlName="country" placeholder="Country"
                                                        [options]="countrySelect2Options"
                                                        (valueChanged)="handleCountryUpdate($event)"
                                                        [ngClass]="{'is-invalid': country.touched && country.invalid}">
                                                    </ng-select2>
                                                    <div *ngIf="country.errors?.required" class="invalid-feedback">
                                                        This field is required.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 mt-1">
                                                <button type="submit" (click)="payWithCard(true)"
                                                    class="default-btn mt-3">Pay
                                                    now</button>
                                            </div>
                                        </div>
                                    </div>
                                </label> <!-- /.plan -->
                            </div>

                        </div>
                    </div> <!-- /#plans -->
                </div>

                <div class="col-12 col-lg-5 mt-5 mt-lg-0">
                    <div id="checkout-details">
                        <h2 class="fs-4 mb-4">Price details</h2>

                        <div class="checkout-details">
                            <table class="table text-muted">
                                <tr>
                                    <td>Total items</td>
                                    <td class="text-end">{{ cartItems.items.length }}</td>
                                </tr>
                                <tr>
                                    <td>Total recharge amount</td>
                                    <td class="text-end">{{ cartItems.totalRechargeAmount }} USD</td>
                                </tr>
                                <tr>
                                    <td>Processing fee</td>
                                    <td class="text-end">{{ cartItems.totalServiceCharge }} USD</td>
                                </tr>
                            </table>
                            <hr>
                            <table class="table mt-3">
                                <tr>
                                    <td class="fs-6">Total amount</td>
                                    <td class="fs-6 text-end">{{ cartItems.totalAmount }} USD</td>
                                </tr>
                            </table>
                        </div>
                    </div> <!-- /#plans -->
                </div>

            </div> <!-- /.row -->
        </ng-container>

    </div> <!-- /.container -->
</section>

<ng-container [ngSwitch]="loadingState">
    <app-loader *ngSwitchCase="loadingStates.retrievingItems" primaryMessage="Fetching cart items"
        secondaryMessage="Please wait..."></app-loader>
    <app-loader *ngSwitchCase="loadingStates.retrievingPaymentMethods" primaryMessage="Fetching payment methods"
        secondaryMessage="Please wait..."></app-loader>
    <app-loader *ngSwitchCase="loadingStates.processingPayment" primaryMessage="Processing your payment"
        secondaryMessage="Please wait..."></app-loader>
    <app-loader *ngSwitchCase="loadingStates.other"></app-loader>
</ng-container>