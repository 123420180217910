jQuery(function ($) {
	'use strict';

	// Header Sticky
	$(window).on('scroll', function () {
		if ($(this).scrollTop() > 120) {
			$('.navbar-area').addClass("is-sticky");
		}
		else {
			$('.navbar-area').removeClass("is-sticky");
		}
	});

	// Mean Menu
	jQuery('.mean-menu').meanmenu({
		meanScreenWidth: "1199"
	});

	// Button Hover JS
	$(function () {
		$('.default-btn')
			.on('mouseenter', function (e) {
				var parentOffset = $(this).offset(),
					relX = e.pageX - parentOffset.left,
					relY = e.pageY - parentOffset.top;
				$(this).find('span').css({ top: relY, left: relX })
			})
			.on('mouseout', function (e) {
				var parentOffset = $(this).offset(),
					relX = e.pageX - parentOffset.left,
					relY = e.pageY - parentOffset.top;
				$(this).find('span').css({ top: relY, left: relX })
			});
	});

	// Search Popup JS
	$('.close-btn').on('click', function () {
		$('.search-overlay').fadeOut();
		$('.search-btn').show();
		$('.close-btn').removeClass('active');
	});
	$('.search-btn').on('click', function () {
		$(this).hide();
		$('.search-overlay').fadeIn();
		$('.close-btn').addClass('active');
	});

	// Projects Slider
	$('.projects-slider').owlCarousel({
		loop: true,
		nav: true,
		dots: false,
		smartSpeed: 500,
		margin: 30,
		autoplayHoverPause: true,
		autoplay: true,
		navText: [
			"<i class='flaticon-left-arrow'></i>",
			"<i class='flaticon-right-arrow'></i>"
		],
		responsive: {
			0: {
				items: 1
			},
			576: {
				items: 1
			},
			768: {
				items: 2
			},
			1024: {
				items: 3
			},
			1200: {
				items: 4
			}
		}
	});

	// Partner Slider
	$('.partner-slider').owlCarousel({
		loop: true,
		nav: false,
		dots: false,
		smartSpeed: 2000,
		margin: 30,
		autoplayHoverPause: true,
		autoplay: true,
		autoWidth: true,
		responsive: {
			0: {
				items: 1
			},
			576: {
				items: 1
			},
			768: {
				items: 3
			},
			1024: {
				items: 4
			},
			1200: {
				items: 5
			}
		}
	});

	// Tabs List
	(function ($) {
		$('.tab ul.tabs-list').addClass('active').find('> li:eq(0)').addClass('current');
		$('.tab ul.tabs-list li').on('click', function (g) {
			var tab = $(this).closest('.tab'),
				index = $(this).closest('li').index();
			tab.find('ul.tabs-list > li').removeClass('current');
			$(this).closest('li').addClass('current');
			tab.find('.tab_content').find('div.tabs_item').not('div.tabs_item:eq(' + index + ')').slideUp();
			tab.find('.tab_content').find('div.tabs_item:eq(' + index + ')').slideDown();
			g.preventDefault();
		});
	})(jQuery);

	// Clients Slider
	$('.clients-slider').owlCarousel({
		loop: true,
		nav: false,
		dots: true,
		smartSpeed: 2000,
		margin: 30,
		autoplayHoverPause: true,
		autoplay: true,
		items: 1,
	});

	// Odometer JS
	$('.odometer').appear(function (e) {
		var odo = $(".odometer");
		odo.each(function () {
			var countNumber = $(this).attr("data-count");
			$(this).html(countNumber);
		});
	});

	// FAQ Accordion
	$(".accordion .accordion-item").on("click", function () {
		$(this).toggleClass('active');
	});

	// Nice Select JS
	$('.nice-select').niceSelect();

	$(".select2-hidden-accessible").on("select2:open", function () {
		$(".select2-search--dropdown .select2-search__field").attr("placeholder", "Search here");
	});

	// Popup Video
	$('.popup-youtube').magnificPopup({
		disableOn: 320,
		type: 'iframe',
		mainClass: 'mfp-fade',
		removalDelay: 160,
		preloader: false,
		fixedContentPos: false
	});

	// Best Services Slider JS
	$(document).ready(function () {
		var bigimage = $("#best-services");
		var thumbs = $("#thumbs");

		// var totalslides = 10;
		var syncedSecondary = true;

		bigimage
			.owlCarousel({
				items: 1,
				slideSpeed: 2000,
				nav: true,
				autoplay: true,
				dots: false,
				nav: true,
				loop: true,
				responsiveRefreshRate: 200,
				navText: [
					'<i class="flaticon-left-arrow"></i>',
					'<i class="flaticon-right-arrow"></i>'
				]
			});

		thumbs
			.on("initialized.owl.carousel", function () {
				thumbs
					.find(".owl-item")
					.eq(0)
					.addClass("current");
			})
			.owlCarousel({
				loop: false,
				dots: false,
				nav: true,
				autoplay: true,
				active: true,
				navText: [
					'<i class="flaticon-left-arrow"></i>',
					'<i class="flaticon-right-arrow"></i>'
				],
				smartSpeed: 200,
				slideSpeed: 500,
				slideBy: 8,
				responsiveRefreshRate: 100,
				responsive: {
					0: {
						items: 2
					},
					1024: {
						items: 4
					},
					1200: {
						items: 6
					}
				}
			})
			.on("changed.owl.carousel", syncPosition2);

		function syncPosition(el) {
			// if loop is set to false, then you have to uncomment the next line
			// var current = el.item.index;

			// to disable loop, comment this block
			var count = el.item.count - 1;
			var current = Math.round(el.item.index - el.item.count / 2 - 0.5);

			if (current < 0) {
				current = count;
			}
			if (current > count) {
				current = 0;
			}
			//to this
			thumbs
				.find(".owl-item")
				.removeClass("current")
				.eq(current)
				.addClass("current");
			var onscreen = thumbs.find(".owl-item.active").length - 1;
			var start = thumbs
				.find(".owl-item.active")
				.first()
				.index();
			var end = thumbs
				.find(".owl-item.active")
				.last()
				.index();

			if (current > end) {
				thumbs.data("owl.carousel").to(current, 100, true);
			}
			if (current < start) {
				thumbs.data("owl.carousel").to(current - onscreen, 100, true);
			}
		}
		function syncPosition2(el) {
			if (syncedSecondary) {
				var number = el.item.index;
				bigimage.data("owl.carousel").to(number, 100, true);
			}
		}
		thumbs.on("click", ".owl-item", function (e) {
			e.preventDefault();
			var number = $(this).index();
			bigimage.data("owl.carousel").to(number, 300, true);
		});
	});

	// Go to Top
	$(function () {
		// Scroll Event
		$(window).on('scroll', function () {
			var scrolled = $(window).scrollTop();
			if (scrolled > 600) $('.go-top').addClass('active');
			if (scrolled < 600) $('.go-top').removeClass('active');
		});
		// Click Event
		$('.go-top').on('click', function () {
			$("html, body").animate({ scrollTop: "0" }, 500);
		});
	});

}(jQuery));