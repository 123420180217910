import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/@core/services';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  error?: string;
  isSubmitting = false;
  isFormSubmitAttempted: boolean = false;
  authForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private fb: FormBuilder
  ) {
    this.authForm = this.fb.group({
      'email': ['', [Validators.required, Validators.email]]
    });
  }

  ngOnInit(): void {
  }

  get email() {
    return this.authForm.get('email');
  }

  async submitForm() {
    this.isFormSubmitAttempted = true;

    if (this.authForm.invalid) return;

    this.isSubmitting = true;
    this.error = undefined;

    try {
      await this.userService.sendVerificationCode(this.email.value).toPromise();
      this.router.navigateByUrl(`/auth/verify/${this.email.value}`);
    } catch (err) {
      console.log("Error: ", err);
      this.error = err?.message || "Something went wrong.";
      this.isSubmitting = false;
    }
  }

}
