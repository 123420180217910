import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/@core/services';
import { Location } from '@angular/common';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {
  error?: string;
  isSubmitting = false;
  isFormSubmitAttempted: boolean = false;
  email: string;
  authForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private fb: FormBuilder,
    private _location: Location,
  ) {
    this.authForm = this.fb.group({
      'code': ['', [Validators.required, Validators.minLength(4)]]
    });
  }

  ngOnInit(): void {
    this.email = this.route.snapshot.paramMap.get("email");

    if (!this.email)
      this.router.navigateByUrl('/auth/sign-in', { replaceUrl: true });
  }

  get code() {
    return this.authForm.get('code');
  }

  async submitForm() {
    this.isFormSubmitAttempted = true;

    if (this.authForm.invalid) return;

    this.isSubmitting = true;
    this.error = undefined;

    const code = this.authForm.get('code').value;
    this.userService
      .login(this.email, code)
      .subscribe(
        (data) => {
          this.router.navigateByUrl('/selfcare');
        },
        (err) => {
          this.error = err?.message || "Something went wrong.";
          this.isSubmitting = false;
        }
      );
  }

  goBack() {
    this._location.back();
  }

}
