<section class="deserve-area pt-100 pb-100">
    <div class="container">
        <div class="section-title">
            <!-- <span>Funding circles</span> -->
            <h2>How to delete your account?</h2>
            <p>Quick and easy steps to follow. Deleting your account will result in the deletion of all the data associated to your account.</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="deserve-item">
                    <!-- <h3>Quick and easy steps to follow.</h3> -->

                    <div class="deserve-content">
                        <span>1</span>
                        <h4>Get the app</h4>
                        <p>Install the app from Play store or App store if you do not already have it installed.</p>
                        <div class="mt-3">
                            <a href="https://apps.apple.com/us/app/24-7-topup/id1572440714" class="default-btn p-0">
                                <img src="assets/img/download-appstore.png" alt="" style="width: 130px;">
                                <!-- View More<span></span> -->
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.topup_247"
                                class="default-btn p-0 ms-2">
                                <img src="assets/img/download-play.png" alt="" style="width: 130px;">
                                <!-- View More<span></span> -->
                            </a>
                        </div>
                    </div>
                    <div class="deserve-content">
                        <span>2</span>
                        <h4>Sign in</h4>
                        <p>Sign in to your account using your user name and verificaton code.</p>
                    </div>
                    <div class="deserve-content">
                        <span>3</span>
                        <h4>Navigate to 'account' page</h4>
                        <p>Navigate to the 'account' page by tapping the 'account' menu from the bottom navigation bar.</p>
                    </div>
                    <div class="deserve-content">
                        <span>4</span>
                        <h4>Delete account</h4>
                        <p>Tap on the 'Delete account' button from the account page. Confirm deletion when propted.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <img src="assets/img/company2-1.png" alt="">
            </div>
        </div>
    </div>
</section>