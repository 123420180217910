import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/@core/services';
import { Provider } from '../models/provider';

@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  constructor(
    private apiService: ApiService
  ) {
    // 
  }

  retrieveAll(countryIso: string): Observable<Provider[]> {
    return this.apiService.get('/providers', new HttpParams({
      fromObject: { countryIso }
    }));
  }
}
