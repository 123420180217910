import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  yearsSince2019: number = 0;

  constructor() { 
    this.yearsSince2019 = (new Date()).getFullYear() - 2019;
  }

  ngOnInit(): void {
  }

}
