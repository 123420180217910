import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NoAuthGuard } from 'src/app/@core/services';
import { AuthLayoutComponent } from './components/auth-layout/auth-layout.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { VerifyComponent } from './verify/verify.component';

const routes: Routes = [
  {
    path: "auth",
    component: AuthLayoutComponent,
    children: [
      { 
        path: 'sign-in', 
        component: SignInComponent,
        canActivate: [NoAuthGuard],
      },
      { 
        path: 'verify/:email', 
        component: VerifyComponent, 
        canActivate: [NoAuthGuard],
      },
      { 
        path: 'sign-up', 
        component: SignUpComponent,
        canActivate: [NoAuthGuard],
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
