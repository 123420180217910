<section class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/img/footer-logo.png" alt="image"></a>
                    </div>
                    <p> Created with your family in mind. Keep talking and stay connected with the most important people
                        in your life using 24-7 Topup app.</p>
                    <ul class="social">
                        <li><b>Follow us:</b></li>
                        <li><a href="https://www.facebook.com/pg/24-7Top-up-2353373691611495/posts/?ref=page_internal"
                                target="_blank"><i class="flaticon-facebook"></i></a></li>
                        <li><a href="https://twitter.com/247topup?lang=en" target="_blank"><i
                                    class="flaticon-twitter"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Quick Links</h3>

                    <ul class="quick-links">
                        <li><a routerLink="/faqs">Help (FAQ)</a></li>
                        <!-- <li><a routerLink="#">Blog</a></li> -->
                        <!-- <li><a href="https://247topup.com/appdownload/countries-2/" target="_blank">Countries</a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Other Resources</h3>

                    <ul class="quick-links">
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/terms-conditions">Terms of Service</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Contact Us</h3>

                    <!-- <div class="info-contact">
                        <i class="flaticon-pin"></i>
                        <span>6890 Blvd, The Bronx, NY 1058 New York, USA</span>
                    </div> -->

                    <div class="info-contact">
                        <i class="flaticon-mail"></i>
                        <span><a href="mailto:customerservice@247topup.com">customerservice@247topup.com</a></span>
                        <!-- <span><a href="#">Skype: example</a></span> -->
                    </div>

                    <!-- <div class="info-contact">
                        <i class="flaticon-telephone"></i>
                        <span><a href="tel:1514312-6688">+1 (514) 312-5678</a></span>
                        <span><a href="tel:1514312-6688">+1 (514) 312-6688</a></span>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>

<div class="copy-right-area">
    <div class="container">
        <div class="copy-right-content">
            <p>Copyright @{{copyrightYear}} Product of BAAJA Technologies LLC</p>
        </div>
    </div>
</div>

<div class="go-top">
    <i class='bx bx-chevron-up'></i>
</div>