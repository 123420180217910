import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { UserService } from 'src/app/@core/services';
import { CartService } from '../../services/cart.service';

@Component({
  selector: 'app-selfcare-layout',
  templateUrl: './selfcare-layout.component.html',
  styleUrls: ['./selfcare-layout.component.scss']
})
export class SelfcareLayoutComponent implements OnInit {

  constructor(
    private userService: UserService,
    private cartService: CartService
  ) {
    // 
  }

  ngOnInit(): void {
    this.initialize();
  }

  async initialize() {
    const user = await this.userService.currentUser
      .pipe(first()).toPromise();
    this.cartService.retrieveAll(user.email).toPromise()
      .catch(error => console.error("Failed getting cart items: ", error));
  }

}
