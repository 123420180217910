import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Select2OptionData } from 'ng-select2';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { UserService } from 'src/app/@core/services';
import { Country } from '../../models/country';
import { CustomPlan } from '../../models/customPlan';
import { Plan } from '../../models/plan';
import { Provider } from '../../models/provider';
import { CartService } from '../../services/cart.service';
import { CountryService } from '../../services/country.service';
import { EstimatedPriceService } from '../../services/estimated-price.service';
import { PlanService } from '../../services/plan.service';
import { ProviderService } from '../../services/provider.service';
import { RechargeService } from '../../services/recharge.service';

declare const jQuery: any;

export enum LoadingState {
  none,
  retrievingCountries,
  retrievingProviders,
  retrievingPlans,
  validatingDetails,
  addingToCart,
  other,
}

@Component({
  selector: 'app-topup',
  templateUrl: './topup.component.html',
  styleUrls: ['./topup.component.scss']
})
export class TopupComponent implements OnInit {
  loadingStates = LoadingState;
  loadingState: LoadingState = LoadingState.none;
  error?: string;

  isFormSubmitAttempted: boolean = false;

  phoneNumberChangeSubscription: Subscription;

  countries: Country[] = [];
  providers: Provider[] = [];
  plans: Plan[] = [];
  customPlans: CustomPlan[] = [];

  form: FormGroup;

  countryIsoValueChanges: Subscription;

  constructor(
    private fb: FormBuilder,
    private countryService: CountryService,
    private providerService: ProviderService,
    private planService: PlanService,
    private estimatedPriceService: EstimatedPriceService,
    private rechargeService: RechargeService,
    private cartService: CartService,
    private userService: UserService,
    private toastr: ToastrService
  ) {
    this.form = this.fb.group({
      'country': ['', [
        Validators.required,
        this.countryValidator(),
      ]],
      'provider': ['', [
        Validators.required,
        this.providerValidator(),
      ]],
      'phoneNumber': ['', [
        Validators.required,
        this.phoneNumberValidator(),
      ]]
    })
  }

  ngOnInit(): void {
    this.retrieveCountries();

    this.phoneNumberChangeSubscription =
      this.phoneNumber.valueChanges.subscribe(() => {
        if (this.plans || this.customPlans) {
          this.plans = [];
          this.customPlans = [];
        }
      })
  }

  ngOnDestroy(): void {
    this.phoneNumberChangeSubscription?.unsubscribe();
  }

  get countriesFieldData(): Select2OptionData[] {
    return this.countries.map(country => {
      return <Select2OptionData>{
        id: country.country_iso,
        text: `${country.country_name} (+${country.country_code})`,
        additional: {
          image: country.flag
        }
      };
    })
  }

  get providersFieldData(): Select2OptionData[] {
    return this.providers.map(provider => {
      return <Select2OptionData>{
        id: provider.ProviderCode,
        text: provider.Name,
        additional: {
          image: provider.LogoUrl
        }
      };
    })
  }

  get country() {
    return this.form.get('country')
  }

  get provider() {
    return this.form.get('provider')
  }

  get phoneNumber() {
    return this.form.get('phoneNumber')
  }

  get isProviderDisabled() {
    return !!this.country.value;
  }

  get isPhoneNumberDisabled() {
    return this.isProviderDisabled || !!this.provider.value;
  }

  get phoneNumberMinLength() {
    const country = this.countries.find((value) => value.country_iso === this.country.value);
    return country ? country.min_phone_number_length - country.country_code.length : 0;
  }

  get phoneNumberMaxLength() {
    const country = this.countries.find((value) => value.country_iso === this.country.value);
    return country ? country.max_phone_number_length - country.country_code.length : 0;
  }

  get shouldShowCustomPlans() {
    return this.plans &&
      (this.plans[0].Maximum.SendValue !== this.plans[0].Minimum.SendValue);
  }

  templateResult(state: Select2OptionData) {
    if (!state.id) {
      return state.text;
    }

    if (!state.additional.image) {
      return state.text;
    } else {
      const option = jQuery(
        '<span><img class="select2-option-image" src="' + state.additional.image + '" /> ' + state.text + '</span>'
      );
      return option;
    }
  }

  countryValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) return null;
      const country = this.countries.find((value) => value.country_iso === control.value);
      return !country ? { inavlidCountry: { value: control.value } } : null;
    };
  }

  providerValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) return null;
      const provider = this.providers.find((value) => value.ProviderCode === control.value);
      return !provider ? { inavlidProvider: { value: control.value } } : null;
    };
  }

  phoneNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) return null;

      if (/^$[0-9]+/g.test(control.value)) {
        return {
          invalidPhoneNumber: {
            value: control.value,
            message: "Invalid phone number."
          }
        };
      }

      const country = this.countries.find((value) =>
        value.country_iso === this.country.value);

      const phoneNumber = `${country.country_code}${control.value}`;

      if (!(phoneNumber.length >= country.min_phone_number_length &&
        phoneNumber.length <= country.max_phone_number_length)) {
        return {
          invalidPhoneNumber: {
            value: control.value,
            message: country.min_phone_number_length == country.max_phone_number_length
              ? `Phone number must have ${country.min_phone_number_length - country.country_code.length} digits.`
              : `Phone number must have between ${country.min_phone_number_length - country.country_code.length} and ${country.max_phone_number_length - country.country_code.length} digits.`
          }
        };
      }

      return null;
    };
  }

  resetForm() {
    this.isFormSubmitAttempted = false;
    this.form.markAsUntouched();
    this.form.reset();

    this.providers = [];
    this.plans = [];
    this.customPlans = [];
  }

  handleCountryUpdate(countryIso?: string) {
    this.providers = [];
    this.plans = [];
    this.customPlans = [];

    if (!countryIso) return

    this.retrieveProviders(countryIso)
  }

  handleProviderUpdate(providerCode?: string) {
    this.plans = [];
    this.customPlans = [];

    if (!providerCode) return

    this.phoneNumber.enable();
  }

  retrieveCountries() {
    this.loadingState = LoadingState.retrievingCountries;
    this.error = undefined;

    this.provider.disable();
    this.phoneNumber.disable();

    this.countryService.retrieveAll().toPromise()
      .then(countries => {
        this.countries = countries;
        this.loadingState = LoadingState.none;
      })
      .catch(error => {
        console.error("Error: ", error);
        this.loadingState = LoadingState.none;
        this.error = "Failed retrieving countries";
      });
  }

  retrieveProviders(countryIso: string) {
    this.error = undefined;
    this.loadingState = LoadingState.retrievingProviders;

    this.phoneNumber.setValue('');
    this.phoneNumber.disable();

    this.providerService.retrieveAll(countryIso).toPromise()
      .then(providers => {
        this.providers = providers;
        this.loadingState = LoadingState.none;

        this.provider.enable();
      })
      .catch(error => {
        console.error("Error: ", error);
        this.loadingState = LoadingState.none;
        this.error = "Failed retrieving providers";
      });
  }

  async searchPlans() {
    this.isFormSubmitAttempted = true;
    this.customPlans = [];

    if (this.form.invalid) return;

    this.error = undefined;
    this.loadingState = LoadingState.retrievingPlans;

    const data = this.form.value;

    try {
      this.plans = await this.planService.retrieveAll(data.provider).toPromise();

      if (!this.plans.length) {
        this.loadingState = LoadingState.none;
        return;
      }

      const skuCode = this.plans[0].SkuCode;

      const estimatedPrices = await this.estimatedPriceService
        .retrieveAll(skuCode).toPromise();

      estimatedPrices.forEach(value => {
        if (value.SkuCode.includes(skuCode)) {
          let selectEstPrise;

          if (value.SkuCode == "LR_LC_TopUp") {
            selectEstPrise = value.Price.SendValue;
          } else {
            if (value.Price.SendValue <= 10) {
              selectEstPrise = value.Price.SendValue + 0.5;
            } else {
              if (value.Price.SendValue == 13.5) {
                selectEstPrise = value.Price.SendValue + 1.5;
              } else if (value.Price.SendValue == 18.0) {
                selectEstPrise = value.Price.SendValue + 2.0;
              } else if (value.Price.SendValue == 22.5) {
                selectEstPrise = value.Price.SendValue + 2.5;
              } else if (value.Price.SendValue == 27) {
                selectEstPrise = value.Price.SendValue + 3.0;
              }
            }
          }

          if (selectEstPrise) {
            this.customPlans.push({
              receiveValue: value.Price.ReceiveValue,
              receiveCurrencyIso: `${value.Price.ReceiveCurrencyIso}`,
              sendValue: selectEstPrise,
              sendCurrencyIso: `${value.Price.SendCurrencyIso}`,
              skuCode: `${value.SkuCode}`
            });
          }
        }
      });

      this.loadingState = LoadingState.none;
    } catch (error) {
      console.error("Error: ", error);
      this.loadingState = LoadingState.none;
      this.error = "Failed retrieving plans";
    }
  }

  async addPlanToCart(plan: Plan) {
    this.error = undefined;
    this.loadingState = LoadingState.validatingDetails;

    // Validate transaction
    try {
      await this.rechargeService.validateRecharge(plan.SkuCode, plan.Maximum.SendValue,
        this.phoneNumber.value).toPromise();
    } catch (error) {
      console.error("Error: ", error);
      this.loadingState = LoadingState.none;
      this.toastr.error(error.message || 'Something went wrong while validating information.');
      return;
    }

    // Add to cart
    this.loadingState = LoadingState.addingToCart;
    try {
      const user = await this.userService.currentUser.pipe(first()).toPromise();
      const country = this.countries.find(value => value.country_iso === this.country.value);
      const provider = this.providers.find(value => value.ProviderCode === this.provider.value);
      await this.cartService.addItem({
        email: user.email,
        price: plan.Maximum.SendValue,
        phone: `${country.country_code}${this.phoneNumber.value}`,
        country: country.country_name,
        provider: provider.Name,
        isCustom: false,
        skuCode: plan.SkuCode,
        sendCurrencyIso: plan.Maximum.SendCurrencyIso,
        countryIso: country.country_iso,
        receiveCurrencyIso: plan.Maximum.ReceiveCurrencyIso,
        receiveAmount: plan.Maximum.ReceiveValue
      }).toPromise();

      // Update cart items
      await this.cartService.retrieveAll(user.email).toPromise()
        .catch(error => console.warn("Failed to update cart items count: ", error));
    } catch (error) {
      console.error("Error: ", error);
      this.loadingState = LoadingState.none;
      this.toastr.error(error.message || 'Something went wrong while adding to cart');
      return;
    }

    this.loadingState = LoadingState.none;
    this.resetForm();
    this.toastr.success(
      "Your recharge plan was added to cart.",
      "Added to cart");
  }

  async addCustomPlanToCart(customPlan: CustomPlan) {
    this.error = undefined;
    this.loadingState = LoadingState.validatingDetails;

    try {
      // Validate transaction
      const sendValue = customPlan.sendValue <= 10.0
        ? customPlan.sendValue - 0.5
        : customPlan.sendValue - 1.0;
      await this.rechargeService.validateRecharge(customPlan.skuCode, sendValue,
        this.phoneNumber.value).toPromise();
    } catch (error) {
      console.error("Error: ", error);
      this.loadingState = LoadingState.none;
      this.toastr.error(error.message || error.toString());
      return;
    }

    // Add to cart
    this.loadingState = LoadingState.addingToCart;
    try {
      const user = await this.userService.currentUser.pipe(first()).toPromise();
      const country = this.countries.find(value => value.country_iso === this.country.value);
      const provider = this.providers.find(value => value.ProviderCode === this.provider.value);
      await this.cartService.addItem({
        email: user.email,
        price: customPlan.sendValue,
        phone: `${country.country_code}${this.phoneNumber.value}`,
        country: country.country_name,
        provider: provider.Name,
        isCustom: false,
        skuCode: customPlan.skuCode,
        sendCurrencyIso: customPlan.sendCurrencyIso,
        countryIso: country.country_iso,
        receiveCurrencyIso: customPlan.receiveCurrencyIso,
        receiveAmount: customPlan.receiveValue
      }).toPromise();

      // Update cart items
      await this.cartService.retrieveAll(user.email).toPromise()
        .catch(error => console.warn("Failed to update cart items count: ", error));
    } catch (error) {
      console.error("Error: ", error);
      this.loadingState = LoadingState.none;
      this.toastr.error(error.message || 'Something went wrong while adding to cart');
      return;
    }

    this.loadingState = LoadingState.none;
    this.resetForm();
    this.toastr.success("You plan was added to cart.");
  }

}
